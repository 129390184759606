import { useStreamV2OrderBookTopQuotes } from '@hooks/useStreamV2OrderBookTopQuotes';
import { useStreamV2Product } from '@hooks/useStreamV2Product';
import { FULLMO_TENOR_ID } from '@protos/grids';
import { OrderBookTopMessage } from '@protos/v2/orderBookTop';
import { Ticker, tickerMeta } from '@protos/v2/ticker';
import { useDashboardContext } from '@shared/contexts/DashboardContext';
import debounce from 'lodash/debounce';
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { RollingRowSettings } from '../shared/types';
import { BidOfferType } from './types';
import { PricingGrid, usePricingGridState } from './usePricingGridState';

type Props = {
  children: ReactNode;
  widgetId: string;
  initialValues: {
    selectedColumns: string[];
    selectedRows: string[];
    columnsOrder: string[];
    userRollingRowSettings: RollingRowSettings;
    isOverrideRolling: boolean;
    selectedBidOfferColumns?: BidOfferType[];
  };
};

const PricesContext = createContext<PricingGrid | undefined>(undefined);
PricesContext.displayName = 'PricesContext';

export default function PricesProvider({ widgetId, initialValues, ...otherProps }: Props) {
  const {
    selectedColumns,
    selectedRows,
    columnsOrder: initialColumnsOrder,
    userRollingRowSettings,
    isOverrideRolling: initialIsOverrideLoading,
    selectedBidOfferColumns: initialSelectedBidOfferColumns,
  } = { ...initialValues };

  const gridState = usePricingGridState(
    selectedColumns,
    selectedRows,
    initialColumnsOrder,
    userRollingRowSettings,
    initialIsOverrideLoading,
    initialSelectedBidOfferColumns || []
  );

  const {
    selectedColIds,
    selectedRowIds,
    columnsOrder,
    rollingRowSettings,
    isOverrideRolling,
    gridRef,
    productMap,
    tenorMap,
    selectedUserProducts,
    selectedBidOfferColumns,
  } = gridState;

  const { editWidgetPayloadById } = useDashboardContext();
  const isFirstUnmountRef = useRef(true);

  useEffect(() => {
    const debouncedEditWidgetPayloadById = debounce(
      (selectedColIds, selectedRowIds, columnsOrder, rollingRowSettings, isOverrideRolling, selectedBidOfferColumns) => {
        editWidgetPayloadById(widgetId, {
          selectedColumns: selectedColIds,
          selectedRows: selectedRowIds,
          columnsOrder: columnsOrder,
          userRollingRowSettings: rollingRowSettings,
          isOverrideRolling,
          selectedBidOfferColumns,
        });
      },
      1000
    );

    debouncedEditWidgetPayloadById(selectedColIds, selectedRowIds, columnsOrder, rollingRowSettings, isOverrideRolling, selectedBidOfferColumns);

    return () => {
      debouncedEditWidgetPayloadById.cancel();
    };
  }, [selectedColIds, selectedRowIds, columnsOrder, rollingRowSettings, isOverrideRolling, selectedBidOfferColumns]);

  const tickerCallback = useMemo(() => {
    return (ticker: Ticker) => {
      const meta = tickerMeta(ticker, productMap, tenorMap);
      if (!meta || !gridRef.current) return;

      const gridApi = gridRef.current.getGridApi();
      const { tenor, productSymbolRoot } = meta;

      const rowNode = gridApi?.getRowNode(tenor.code);
      const rowFullPriceNode = gridApi?.getRowNode(FULLMO_TENOR_ID);
      const midColId = gridApi?.getColumnDef(`${productSymbolRoot}-mid`)?.colId;

      if (!rowNode || !rowNode.data || !midColId) return;

      rowNode.setDataValue(midColId, ticker.mid);
      if (rowFullPriceNode && ticker.full_price) rowFullPriceNode.setDataValue(midColId, ticker.full_price);

      gridApi.refreshClientSideRowModel();
    };
  }, [productMap, tenorMap, gridRef]);

  useStreamV2Product(
    tickerCallback,
    selectedUserProducts.map(product => product.symbol)
  );

  const orderBookTopCallback = useCallback(
    (orderBookTop: OrderBookTopMessage) => {
      const meta = tickerMeta(orderBookTop, productMap, tenorMap);
      if (!meta || !gridRef.current) return;

      const gridApi = gridRef.current.getGridApi();
      const { tenor, productSymbolRoot } = meta;

      const rowNode = gridApi?.getRowNode(tenor.code);
      const bidColId = gridApi?.getColumnDef(`${productSymbolRoot}-bid`)?.colId;
      const offerColId = gridApi?.getColumnDef(`${productSymbolRoot}-offer`)?.colId;

      if (!rowNode || !rowNode.data || !bidColId || !offerColId) return;

      rowNode.setDataValue(bidColId, orderBookTop.buy.price);
      rowNode.setDataValue(offerColId, orderBookTop.sell.price);

      gridApi?.refreshClientSideRowModel();
    },
    [productMap, tenorMap, gridRef]
  );

  const { unsubscribeAllSymbols } = useStreamV2OrderBookTopQuotes(
    orderBookTopCallback,
    selectedUserProducts.map(product => product.symbol)
  );

  const value = useMemo(() => ({ ...gridState }), [gridState]);

  useEffect(() => {
    return () => {
      if (isFirstUnmountRef.current) {
        isFirstUnmountRef.current = false;
        return;
      }

      unsubscribeAllSymbols();
    };
  }, []);

  return <PricesContext.Provider value={value} {...otherProps} />;
}

export function usePricesSettings(): PricingGrid {
  const context = useContext(PricesContext);

  if (!context) {
    throw new Error('usePricesSettings must be used within PricesProvider');
  }

  return context;
}
