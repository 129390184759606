import { streamV2ProductRisk } from '@services/context';
import { ProductRiskCallback } from '@services/v2/streamV2ProductRisk';
import { useEffect, useRef } from 'react';

export const useStreamV2ProductRisk = (callback: ProductRiskCallback, symbol: string, accountId: string, isReadyToSubscribe?: boolean) => {
  const target = useRef<ProductRiskCallback>(callback);

  useEffect(() => {
    if (!symbol || !accountId || !isReadyToSubscribe) return;

    target.current = callback;
    streamV2ProductRisk.subscribe({ product_symbol: symbol, account_id: accountId }, callback);

    return () => {
      streamV2ProductRisk.unsubscribe({ product_symbol: symbol, account_id: accountId }, callback);
    };
  }, [symbol, accountId, isReadyToSubscribe]);
};
