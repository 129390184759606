import { useScreenSize } from '@hooks/useScreenSize';
import { Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import ToggleButton, { toggleButtonClasses } from '@mui/material/ToggleButton';
import ToggleButtonGroup, { toggleButtonGroupClasses } from '@mui/material/ToggleButtonGroup';
import { ColumnState } from 'ag-grid-enterprise';
import { useCallback, useEffect, useState } from 'react';
import { usePricesSettings } from '../PricesContext';
import { BidOfferType } from '../types';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    border: '2px solid transparent !important',
    backgroundColor: `${alpha(theme.palette.primary.main, 0.1)}!important`,
    color: alpha(theme.palette.text.secondary, 0.5),
    textTransform: 'none',
    '&:not(:first-of-type)': {
      marginLeft: '3px !important',
    },
    '&:first-of-type': {
      borderRadius: '0px',
    },
    '&:last-of-type': {
      borderRadius: '0px',
    },
    [`&.${toggleButtonClasses.selected}`]: {
      color: theme.palette.text.primary,
      backgroundColor: `${alpha(theme.palette.primary.main, 0.5)}!important`,
    },
    [`&.${toggleButtonClasses.disabled}`]: {
      border: 0,
    },
  },
}));

export const ToggleBidOffer = () => {
  const { gridRef, selectedBidOfferColumns, setNewBidOfferColumns } = usePricesSettings();

  const { isMobile } = useScreenSize();

  const [activeColumns, setActiveColumns] = useState<BidOfferType[]>(selectedBidOfferColumns);

  const onActiveColumnsChange = useCallback((_: React.MouseEvent<HTMLElement>, newActiveColumns: BidOfferType[]) => {
    if (!gridRef?.current) {
      return;
    }

    const gridApi = gridRef.current.getGridApi();
    const columnsNewState = gridApi?.getColumnState().reduce<ColumnState[]>((acc, { colId }) => {
      const columnType = colId.split('-')[1];
      const isColumnTypeActive = newActiveColumns.find(c => c === columnType) || colId.includes('mid') || colId === 'tenorId';

      acc.push({
        colId,
        hide: !isColumnTypeActive,
      });

      return acc;
    }, []);

    gridApi?.applyColumnState({ state: columnsNewState });

    setActiveColumns(newActiveColumns);
    setNewBidOfferColumns(newActiveColumns);
  }, []);

  useEffect(() => {
    setActiveColumns(selectedBidOfferColumns);
  }, [selectedBidOfferColumns]);

  return (
    <StyledToggleButtonGroup value={activeColumns} onChange={onActiveColumnsChange}>
      {Object.keys(BidOfferType).map(type => (
        <ToggleButton
          value={BidOfferType[type]}
          key={type}
          aria-label={type}
          size={isMobile ? 'small' : 'medium'}
          sx={{ padding: 0.5, minWidth: '50px' }}
        >
          <Typography fontSize="11px">{`${BidOfferType[type].toLocaleUpperCase()}`}</Typography>
        </ToggleButton>
      ))}
    </StyledToggleButtonGroup>
  );
};
