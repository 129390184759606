import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { useStream } from '@shared/hooks/useStream';
import { ReactNode, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { StyledScopedCssBaseline } from '@features/base/ScopedCssBaseline';
import AppFrame from '@features/base/private/AppFrame';
import PageLoadProgress from '@shared/components/PageLoadProgress';
import darkTheme from '@shared/themes/darkTheme';

import { useStreamV2 } from '@hooks/useStreamV2';
import { DashboardProvider } from '@shared/contexts/DashboardContext';
import FloatingChartProvider from '@shared/contexts/FloatingChartContext';
import TradingTenorProvider from '@shared/contexts/TradingTenorProvider';
import UserContextProvider from '@shared/contexts/UserContextProvider';
import UserProductsAndTenorsContextProvider from '@shared/contexts/UserProductsAndTenorsProvider';
import 'ag-grid-enterprise';
import 'src/shared/styles/utils/fonts.css';

type PrivatePageContainerProps = {
  children: ReactNode;
};

function PrivatePageContainer({ children }: PrivatePageContainerProps) {
  return (
    <div id="flux-container">
      <ThemeProvider theme={darkTheme}>
        <StyledScopedCssBaseline enableColorScheme>
          <AppFrame>{children}</AppFrame>
        </StyledScopedCssBaseline>
      </ThemeProvider>
    </div>
  );
}

export default function PrivatePage() {
  useStream();
  useStreamV2();

  return (
    <UserContextProvider>
      <UserProductsAndTenorsContextProvider>
        <DashboardProvider>
          <FloatingChartProvider>
            <TradingTenorProvider>
              <PrivatePageContainer>
                <Suspense fallback={<PageLoadProgress />}>
                  <Outlet />
                </Suspense>
              </PrivatePageContainer>
            </TradingTenorProvider>
          </FloatingChartProvider>
        </DashboardProvider>
      </UserProductsAndTenorsContextProvider>
    </UserContextProvider>
  );
}
