import { DefaultGridState, GridHandlers, GridSettings, RollingRowSettings, Row } from '@features/dashboard/widgets/shared/types';
import { getProducts, getTenors, getTenorsFirstIndexes, getTenorsFromRollingSettings } from '@features/dashboard/widgets/shared/utils';
import { useApi } from '@hooks/useApi';
import { useScreenSize } from '@hooks/useScreenSize';
import { Product, productMaps, ProductTenor } from '@protos/product';
import { useUserProductsAndTenorsContext } from '@shared/contexts/UserProductsAndTenorsProvider';
import { ColumnMovedEvent, GridApi } from 'ag-grid-enterprise';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { mapColumns, mapRows } from '../utils';

export interface GridRef<T> {
  getGridApi: () => GridApi<T> | null;
}

export type PricingSheetGridState = DefaultGridState;

export type PricesSheetRow = Row & {
  [K in Exclude<string, keyof Row>]: string;
};
export type PricingSheetGrid = GridSettings<PricesSheetRow> &
  GridHandlers<PricesSheetRow> & {
    productMap: Record<string, Product>;
    tenorMap: Record<string, ProductTenor>;
  };

export function usePricingSheetGridState(
  selectedColumns: string[],
  selectedRows: string[],
  columnsOrder: string[],
  userRollingRowSettings: RollingRowSettings,
  isOverrideRolling: boolean
): PricingSheetGrid {
  const gridRef = useRef<GridRef<PricesSheetRow>>(null);
  const { userProducts, tenors } = useUserProductsAndTenorsContext();
  const { isMobile } = useScreenSize();
  const { apiClient } = useApi();

  const [state, setState] = useState<PricingSheetGridState>({
    selectedColIds: selectedColumns,
    selectedRowIds: selectedRows,
    rollingRowSettings: userRollingRowSettings,
    columnsOrder: columnsOrder,
    isOverrideRolling,
  });

  const selectedUserProducts = useMemo(() => getProducts(userProducts, state.selectedColIds), [userProducts, state.selectedColIds]);
  const viewableProductColumns = useMemo(
    () =>
      selectedUserProducts.filter(
        product => !product.underlying_symbol || (product.calendar_type === 'spread' && product.tenor_frequency !== 'quarterly')
      ),
    [selectedUserProducts]
  );
  const selectedTenors = useMemo(() => getTenors(tenors, state.selectedRowIds), [tenors, state.selectedRowIds]);
  const { firstWeekIndex, firstMonthIndex } = getTenorsFirstIndexes(selectedTenors, tenors);
  const { productMap, tenorMap } = useMemo(() => productMaps(selectedUserProducts, selectedTenors), [selectedUserProducts, selectedTenors]);

  const toggleColumn = useCallback(
    (id: string) =>
      setState(prevState => ({
        ...prevState,
        selectedColIds: prevState.selectedColIds.includes(id)
          ? prevState.selectedColIds.filter(colId => colId !== id)
          : [...prevState.selectedColIds, id],
      })),
    []
  );

  const addColumn = useCallback(
    (id: string) =>
      setState(prevState => ({
        ...prevState,
        selectedColIds: prevState.selectedColIds.includes(id) ? prevState.selectedColIds : [...prevState.selectedColIds, id],
      })),
    []
  );

  const toggleRow = useCallback((id: string) => {
    setState(prevState => ({
      ...prevState,
      selectedRowIds: prevState.selectedRowIds.includes(id)
        ? prevState.selectedRowIds.filter(rowId => rowId !== id)
        : [...prevState.selectedRowIds, id],
    }));
  }, []);

  const addRow = useCallback((id: string) => {
    setState(prevState => ({
      ...prevState,
      selectedRowIds: prevState.selectedRowIds.includes(id) ? prevState.selectedRowIds : [...prevState.selectedRowIds, id],
    }));
  }, []);

  const updateRollingSettings = useCallback(
    (newSettings: RollingRowSettings) => {
      const newSelectedRowIds = getTenorsFromRollingSettings(tenors, newSettings).map(elem => elem.code);

      setState(prevState => ({
        ...prevState,
        rollingRowSettings: newSettings,
        selectedRowIds: newSelectedRowIds,
      }));
    },
    [tenors]
  );

  const onColumnMoved = useCallback(
    debounce(async ({ api }: ColumnMovedEvent<PricesSheetRow>) => {
      const columnsOrder = api.getColumnState().map(({ colId }) => colId);
      setState(prevState => ({
        ...prevState,
        columnsOrder,
      }));
    }, 1500),
    [apiClient]
  );

  const toggleIsOverrideRolling = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      isOverrideRolling: !prevState.isOverrideRolling,
    }));
  }, []);

  // GET ROW IDS FROM ROLLING SETTINGS IF NOT OVERRIDE ROLLING
  useEffect(() => {
    const { rollingRowSettings, isOverrideRolling } = state;

    if (!isOverrideRolling) {
      const newSelectedRowIds = getTenorsFromRollingSettings(tenors, rollingRowSettings).map(elem => elem.code);

      setState(prevState => ({
        ...prevState,
        selectedRowIds: newSelectedRowIds,
      }));
    }
  }, [tenors]);

  return useMemo(
    () => ({
      ...state,
      columnDefs: {
        lockPinned: true,
      },
      gridRef,
      rows: mapRows(
        selectedTenors,
        selectedUserProducts.filter(({ symbol }) => state.selectedColIds.includes(symbol)),
        firstWeekIndex,
        firstMonthIndex
      ),
      columns: mapColumns(viewableProductColumns, state.selectedColIds, state.columnsOrder, isMobile),
      onColumnMoved,
      toggleColumn,
      addColumn,
      toggleRow,
      addRow,
      updateRollingSettings,
      toggleIsOverrideRolling,
      filteredSelectedTenors: selectedTenors,
      selectedUserProducts,
      productMap,
      tenorMap,
    }),
    [
      selectedTenors,
      selectedUserProducts,
      firstWeekIndex,
      firstMonthIndex,
      state.isOverrideRolling,
      state.selectedColIds,
      state.selectedRowIds,
      state.rollingRowSettings,
      state.columnsOrder,
      viewableProductColumns,
      productMap,
      tenorMap,
      isMobile,
    ]
  );
}
