interface OtcSymbolSpread {
  front: string;
  back: string;
}

interface OtcSymbolButterfly {
  front: string;
  back: string;
  middle: string;
}

export interface OtcQuote {
  price: string;
  amount: string;
}

export type OtcQuoteSymbol = OtcSymbolSpread | OtcSymbolButterfly | string;

export interface OtcQuoteMessage {
  exchange: string;
  mid: string;
  timestamp: string;
  product_symbol: string;
  buy: OtcQuote;
  sell: OtcQuote;
  symbol: OtcQuoteSymbol;
}

export enum OtcExchange {
  ice = 'ice',
  cme = 'cme',
}

export enum OtcOrderType {
  unspecified = 'unspecified',
  fill_or_kill = 'fill_or_kill',
}

export enum OtcSide {
  unspecified = 'unspecified',
  buy = 'buy',
  sell = 'sell',
}

export enum OtcOrderState {
  unspecified = 'unspecified',
  pending_created = 'pending_created',
  created = 'created',
  new = 'new',
  filled = 'filled',
  errored = 'errored',
}

export interface OtcQuoteOrder {
  account_id: string;
  symbol: OtcQuoteSymbol;
  amount: string;
  price: string;
  side: OtcSide;
  order_type: OtcOrderType;
  exchange: OtcExchange;
}

export interface User {
  id: string;
  account_name: string;
  full_name: string;
  email: string;
}
export interface Fee {
  currency: string;
  rate: string;
  signed_amount: string;
}

export interface WebSocketBaseResponse {
  id: string;
  timestamp: string;
  method: string;
}

export interface OtcExecution {
  id: string;
  order_id: string;
  client_order_id: string;
  side: OtcSide;
  account_id: string;
  exchange: OtcExchange;
  created_timestamp: string;
  symbol: string;
  price: string;
  amount: string;
  user: User;
  fee: Fee;
}

export interface OtcQuoteOrderResponse extends WebSocketBaseResponse {
  order_id: string;
  client_order_id: string;
  account_id: string;
  symbol: OtcQuoteSymbol;
  product_symbol: string;
  amount: string;
  side: OtcSide;
  order_type: OtcOrderType;
  order_state: OtcOrderState;
  user: User;
  price: string;
  created_timestamp: string;
  exchange: OtcExchange;
  executed_avg_price: string;
  executed_amount: string;
  executions: OtcExecution[];
  message?: string;
  code?: string;
}
