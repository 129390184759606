import { Box, CircularProgress, MenuItem, Select, Stack, Typography, styled } from '@mui/material';
import { ProductTenor } from '@protos/product';
import { OtcExchange } from '@protos/v2/otcQuote';
import { NumberInput } from '@shared/components/NumberInput';
import { useDashboardContext } from '@shared/contexts/DashboardContext';
import { useTradingTenorContext } from '@shared/contexts/TradingTenorProvider';
import { useUserProductsAndTenorsContext } from '@shared/contexts/UserProductsAndTenorsProvider';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TradingButtons } from './TradingButtons';
import { TradingPanel, useTradingContext } from './TradingContext';
import { DEFAULT_TRADE_SIZE, MIN_SIZE_VALUE } from './constants';

const TradingSelect = styled(Select)(({ theme }) => ({
  fontSize: 11,
  height: 20,
  width: '100%',
  borderRadius: 0,
  '& .MuiSelect-select': {
    padding: 0,
    paddingLeft: 4,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 20,
    padding: 0,
  },
}));

const CustomTradingMenuProps = { PaperProps: { sx: { maxHeight: 100, width: 70, borderRadius: 0 } } };
const CustomTradingMenuItemProps = { fontSize: 11, padding: 0, paddingLeft: 1 };

interface TradingPanelProps {
  id: string;
  payload: TradingPanel;
  userTradeLimits: Record<string, number>;
}

export const TradingPanelComponent = ({ id, payload, userTradeLimits }: TradingPanelProps) => {
  const sizeInputRef = useRef<HTMLDivElement>(null);
  const { editWidgetPayloadById } = useDashboardContext();
  const { tenors } = useUserProductsAndTenorsContext();
  const monthlyTenors = useMemo(() => tenors.filter(tenor => tenor.frequency === 'monthly').slice(2), [tenors]);
  const {
    subscriptionSymbol,
    subscriptionSize,
    subscriptionExchange,
    setSubscriptionSymbol,
    setSubscriptionSize,
    setSubscriptionExchange,
    setPrices,
    unsubscribeSymbol,
  } = useTradingContext();
  const { getAllUsedTenors, setWidgetTenorMap, widgetTenorMap } = useTradingTenorContext();
  const [validTenors, setValidTenors] = useState<ProductTenor[]>(monthlyTenors);
  const [validBackTenors, setValidBackTenors] = useState<ProductTenor[]>(monthlyTenors);

  const [isTradeLimitBreached, setIsTradeLimitBreached] = useState<boolean>(false);
  const [isLoadingPanel, setIsLoadingPanel] = useState<boolean>(true);

  //Set up trading form for the widget
  const {
    selectedProduct: incomingSelectedProduct,
    selectedTenor: incomingSelectedTenor,
    tradeSize: incomingTradeSize,
    selectedTradingAccount: incomingSelectedTradingAccount,
    selectedExchange: incomingSelectedExchange = OtcExchange.ice,
  } = payload;

  const currentWidgetTenorMap = useMemo(() => widgetTenorMap[id], [widgetTenorMap, id]);
  const allowedTradingProducts = useMemo(() => Object.keys(userTradeLimits), [userTradeLimits]);
  const initialSelectedTenor = useMemo(() => {
    if (!monthlyTenors.length) return '';

    if (incomingSelectedTenor && typeof incomingSelectedTenor === 'object') {
      const indexOfLeftTenor = monthlyTenors.findIndex(tenor => tenor?.code === incomingSelectedTenor.left);
      const availableTenors = monthlyTenors.slice(indexOfLeftTenor + 1);
      setValidBackTenors(availableTenors);
      return {
        left: incomingSelectedTenor.left,
        right: availableTenors[0]?.code || incomingSelectedTenor.right,
      };
    }

    const allUsedTenors = getAllUsedTenors(id, false, incomingSelectedExchange) as ProductTenor[];
    const availableTenors = monthlyTenors.filter(tenor => !allUsedTenors.find(t => t?.code === tenor?.code));
    if (!availableTenors.length) return monthlyTenors[0]?.code;

    return availableTenors[0]?.code;
  }, [getAllUsedTenors, monthlyTenors, incomingSelectedExchange, id]);

  const [tradingForm, setTradingForm] = useState<TradingPanel>({
    selectedProduct: incomingSelectedProduct || allowedTradingProducts[0],
    selectedTenor: incomingSelectedTenor || initialSelectedTenor,
    tradeSize: incomingTradeSize || DEFAULT_TRADE_SIZE,
    selectedTradingAccount: incomingSelectedTradingAccount,
    selectedExchange: incomingSelectedExchange,
  });

  const showUserInputError = useMemo(
    () => isTradeLimitBreached || tradingForm.tradeSize <= 0 || tradingForm.tradeSize === undefined,
    [tradingForm, isTradeLimitBreached]
  );
  const productTradingLimit = useMemo(() => userTradeLimits[tradingForm.selectedProduct?.toLocaleLowerCase()], [userTradeLimits, tradingForm]);

  //Form Functions
  const onTradingProductChange = useCallback(
    (value: string) => {
      setIsLoadingPanel(true);
      const { selectedProduct, selectedTenor, selectedExchange } = tradingForm;

      if (!allowedTradingProducts.includes(value) || value === selectedProduct) return;

      const isNewProductSpread = value.toLocaleLowerCase().includes('spr');
      const isCurrentTenorSpread = typeof selectedTenor === 'object';
      const allUsedTenors = getAllUsedTenors(id, isNewProductSpread, selectedExchange);

      if (isNewProductSpread && !isCurrentTenorSpread) {
        const leftTenorCode = monthlyTenors[0]?.code;
        const usedSpreadProductTenors = (allUsedTenors as { left: ProductTenor; right: ProductTenor }[]).filter(t => t.left?.code === leftTenorCode);
        let rightTenorCode = monthlyTenors[1]?.code;
        if (usedSpreadProductTenors.length) {
          const usedRightTenors = usedSpreadProductTenors.map(t => t.right?.code);
          const availableRightTenors = monthlyTenors.filter(tenor => !usedRightTenors.includes(tenor?.code) && tenor?.code !== leftTenorCode);
          rightTenorCode = availableRightTenors[0]?.code;
        }
        setTradingForm((prev: TradingPanel) => ({ ...prev, selectedProduct: value, selectedTenor: { left: leftTenorCode, right: rightTenorCode } }));
      } else if (!isNewProductSpread && isCurrentTenorSpread) {
        const usedNonSprTenors = allUsedTenors as ProductTenor[];
        const availableNonSprTenors = monthlyTenors.filter(tenor => !usedNonSprTenors.find(t => t?.code === tenor?.code));
        setTradingForm((prev: TradingPanel) => ({ ...prev, selectedProduct: value, selectedTenor: availableNonSprTenors[0]?.code }));
      } else {
        setTradingForm((prev: TradingPanel) => ({ ...prev, selectedProduct: value }));
      }
    },
    [tradingForm, getAllUsedTenors, monthlyTenors, allowedTradingProducts]
  );

  const getAvailableRightTenors = useCallback(
    (leftTenorCode: string, usedRightTenors: string[]) => {
      const indexOfLeftTenor = monthlyTenors.findIndex(tenor => tenor?.code === leftTenorCode);
      return monthlyTenors.filter(
        (tenor, index) => !usedRightTenors.includes(tenor?.code) && tenor?.code !== leftTenorCode && index > indexOfLeftTenor
      );
    },
    [monthlyTenors]
  );

  const onTenorChange = useCallback(
    (value: string, key?: 'left' | 'right') => {
      setIsLoadingPanel(true);
      const { selectedTenor, selectedExchange } = tradingForm;
      const isSpreadTenorChange = key !== undefined;
      if (!isSpreadTenorChange) {
        setTradingForm((prev: TradingPanel) => ({ ...prev, selectedTenor: value }));
      } else {
        if (selectedTenor[key] === value) return;
        else if (selectedTenor && typeof selectedTenor === 'object') {
          if (key === 'right') {
            const newTenor = { left: selectedTenor.left, right: value };
            setTradingForm((prev: TradingPanel) => ({ ...prev, selectedTenor: newTenor }));
          } else {
            const allUsedTenors = getAllUsedTenors(id, true, selectedExchange);
            const usedSpreadProductTenors = (allUsedTenors as { left: ProductTenor; right: ProductTenor }[]).filter(t => t.left?.code === value);
            const updatedRightTenors = usedSpreadProductTenors.map(t => t.right?.code);
            const availableRightTenors = getAvailableRightTenors(value, updatedRightTenors);
            const newTenor = { left: value, right: availableRightTenors[0]?.code };
            setTradingForm((prev: TradingPanel) => ({ ...prev, selectedTenor: newTenor }));
          }
        }
      }
    },
    [tradingForm, getAllUsedTenors, monthlyTenors]
  );

  const onExchangeChange = useCallback(
    (value: OtcExchange) => {
      setIsLoadingPanel(true);
      const { selectedProduct, selectedTenor } = tradingForm;

      const isProductSpread = selectedProduct.toLocaleLowerCase().includes('spr');
      const allUsedTenors = getAllUsedTenors(id, isProductSpread, value);

      if (!isProductSpread) {
        const availableNonSprTenors = monthlyTenors.filter(tenor => !(allUsedTenors as ProductTenor[]).find(t => t?.code === tenor?.code));
        setTradingForm((prev: TradingPanel) => ({ ...prev, selectedTenor: availableNonSprTenors[0]?.code, selectedExchange: value }));
      } else {
        const usedSpreadProductTenors = (allUsedTenors as { left: ProductTenor; right: ProductTenor }[]).filter(
          t => t.left?.code === (selectedTenor as { left: string; right: string }).left
        );
        const updatedRightTenors = usedSpreadProductTenors.map(t => t.right?.code);
        const availableRightTenors = getAvailableRightTenors((selectedTenor as { left: string; right: string }).left, updatedRightTenors);
        const newTenor = { left: (selectedTenor as { left: string; right: string }).left, right: availableRightTenors[0]?.code };
        setTradingForm((prev: TradingPanel) => ({ ...prev, selectedTenor: newTenor, selectedExchange: value }));
      }
    },
    [tradingForm, getAllUsedTenors, monthlyTenors]
  );

  const onTradeSizeChange = useCallback(
    debounce((_, newValue) => {
      setTradingForm((prev: TradingPanel) => ({ ...prev, tradeSize: newValue }));

      if (newValue > productTradingLimit) {
        setIsTradeLimitBreached(true);
      } else if (isTradeLimitBreached) {
        setIsTradeLimitBreached(false);
      }
    }, 350),
    [isTradeLimitBreached, productTradingLimit]
  );

  //Highlight Size input field on widget load
  useEffect(() => {
    if (sizeInputRef.current) {
      sizeInputRef.current?.focus();
    }
  }, [sizeInputRef.current]);

  //Save widget based on user input in trading form and update widgetTenorMap
  useEffect(() => {
    const { selectedProduct, selectedTenor, tradeSize, selectedExchange, selectedTradingAccount } = tradingForm;
    if (!selectedProduct || !selectedTenor || !tradeSize || !selectedExchange || !selectedTradingAccount) return;

    const rfqSubscriptionSymbol =
      typeof selectedTenor === 'string'
        ? `${selectedProduct}${selectedTenor}`
        : `${selectedProduct.split('spr')[0]}${selectedTenor.left}-${selectedProduct.split('spr')[0]}${selectedTenor.right}`;

    if (subscriptionSymbol === rfqSubscriptionSymbol && subscriptionSize === tradeSize && subscriptionExchange === selectedExchange) return;

    const isSpread = typeof selectedTenor === 'object';
    const tenorsUsed = isSpread
      ? [
          {
            left: monthlyTenors.find(tenor => tenor?.code === selectedTenor?.left),
            right: monthlyTenors.find(tenor => tenor?.code === selectedTenor?.right),
          },
        ]
      : [monthlyTenors.find(tenor => tenor?.code === selectedTenor)];
    const nonUndefinedTenorsUsed = tenorsUsed.filter(tenor => tenor !== undefined) as ProductTenor[];

    setWidgetTenorMap(prev => ({ ...prev, [id]: { isSpread, exchange: selectedExchange, tenorsUsed: nonUndefinedTenorsUsed } }));
    setPrices({ buy: undefined, sell: undefined });

    const currentSubscriptionSymbolElements = `${subscriptionSymbol}-${subscriptionExchange}`.split('-');

    if (currentSubscriptionSymbolElements.length !== `${rfqSubscriptionSymbol}-${selectedExchange}`.split('-').length) {
      unsubscribeSymbol();
    } else {
      if (currentSubscriptionSymbolElements.length === 3 && typeof selectedTenor !== 'string') {
        if (
          `${selectedProduct.split('spr')[0]}${selectedTenor.left}` !== currentSubscriptionSymbolElements[0] ||
          `${selectedProduct.split('spr')[0]}${selectedTenor.right}` !== currentSubscriptionSymbolElements[1] ||
          currentSubscriptionSymbolElements[2] !== selectedExchange
        )
          unsubscribeSymbol();
      } else if (
        `${selectedProduct}${selectedTenor}` !== currentSubscriptionSymbolElements[0] ||
        selectedExchange !== currentSubscriptionSymbolElements[1]
      ) {
        unsubscribeSymbol();
      }
    }

    if (subscriptionSymbol !== rfqSubscriptionSymbol) setSubscriptionSymbol(rfqSubscriptionSymbol);
    if (subscriptionSize !== tradeSize) setSubscriptionSize(tradeSize);
    if (subscriptionExchange !== selectedExchange) setSubscriptionExchange(selectedExchange);

    editWidgetPayloadById(id, {
      selectedProduct,
      selectedTenor,
      tradeSize,
      selectedTradingAccount,
      selectedExchange,
    });

    if (isLoadingPanel) setIsLoadingPanel(false);
  }, [tradingForm, id, subscriptionSymbol, setWidgetTenorMap, monthlyTenors, subscriptionSize, subscriptionExchange]);

  const isTenorsFiltered = useMemo(() => validTenors.length !== monthlyTenors.length, [validTenors.length, monthlyTenors.length]);

  // Update Tenors based on WidgetTenorMap changes
  useEffect(() => {
    if (!currentWidgetTenorMap) return;

    if (currentWidgetTenorMap.isSpread) {
      const allUsedTenors = getAllUsedTenors(id, true, currentWidgetTenorMap.exchange) as { left: ProductTenor; right: ProductTenor }[];
      const currentWidgetTenor = currentWidgetTenorMap.tenorsUsed[0] as { left: ProductTenor; right: ProductTenor };
      if (isTenorsFiltered) setValidTenors(monthlyTenors);
      if (!currentWidgetTenor || !currentWidgetTenor.left || !currentWidgetTenor.right) return;

      const usedSpreadProductTenors = allUsedTenors.filter(t => t.left?.code === currentWidgetTenor.left?.code);
      const usedRightTenorCodes = usedSpreadProductTenors.map(t => t.right?.code);
      const availableRightTenors = getAvailableRightTenors(currentWidgetTenor.left?.code, usedRightTenorCodes);

      setValidBackTenors(availableRightTenors);
    } else {
      const allUsedTenors = getAllUsedTenors(id, false, currentWidgetTenorMap.exchange) as ProductTenor[];
      const availableNonSprTenors = monthlyTenors.filter(tenor => !allUsedTenors.find(t => t?.code === tenor?.code));

      setValidTenors(availableNonSprTenors);
    }
  }, [currentWidgetTenorMap, id, monthlyTenors, getAllUsedTenors, isTenorsFiltered]);

  useEffect(() => {
    return () => {
      setWidgetTenorMap(prev => {
        const newWidgetTenorMap = { ...prev };
        delete newWidgetTenorMap[id];
        return newWidgetTenorMap;
      });
    };
  }, []);

  useEffect(() => {
    return () => {
      if (subscriptionSymbol) {
        unsubscribeSymbol();
      }
    };
  }, [subscriptionSymbol, unsubscribeSymbol]);

  if (isLoadingPanel)
    return (
      <Stack direction="column" flex={1} alignItems="center" justifyContent="center">
        <Typography fontSize={11} color="textSecondary" tabIndex={-1}>
          Loading...
        </Typography>
      </Stack>
    );

  return (
    <Stack display="flex" flexDirection="column" flex={1} alignItems="center" padding={1} justifyContent="space-between">
      <Stack display="flex" gap={1} width="100%">
        <TradingSelect
          size="small"
          value={tradingForm.selectedProduct}
          onChange={(event: any) => onTradingProductChange(event.target.value)}
          MenuProps={CustomTradingMenuProps}
          tabIndex={-1}
        >
          {allowedTradingProducts.map(option => (
            <MenuItem key={option} value={option} sx={CustomTradingMenuItemProps}>
              {option.toLocaleUpperCase()}
            </MenuItem>
          ))}
        </TradingSelect>
        {tradingForm.selectedTenor && typeof tradingForm.selectedTenor === 'object' ? (
          <Box display="flex" width="100%">
            <TradingSelect
              size="small"
              value={tradingForm.selectedTenor.left}
              onChange={(event: any) => onTenorChange(event.target.value, 'left')}
              MenuProps={CustomTradingMenuProps}
              tabIndex={-1}
            >
              {validTenors.map(option => (
                <MenuItem key={option.code} value={option.code} sx={CustomTradingMenuItemProps}>
                  {option.display.toLocaleUpperCase()}
                </MenuItem>
              ))}
            </TradingSelect>
            <TradingSelect
              size="small"
              value={tradingForm.selectedTenor.right}
              onChange={(event: any) => onTenorChange(event.target.value, 'right')}
              MenuProps={CustomTradingMenuProps}
              tabIndex={-1}
            >
              {validBackTenors.map(option => (
                <MenuItem key={option.code} value={option.code} sx={CustomTradingMenuItemProps}>
                  {option.display.toLocaleUpperCase()}
                </MenuItem>
              ))}
            </TradingSelect>
          </Box>
        ) : (
          <TradingSelect
            size="small"
            value={tradingForm.selectedTenor}
            onChange={(event: any) => onTenorChange(event.target.value)}
            MenuProps={CustomTradingMenuProps}
            tabIndex={-1}
          >
            {validTenors.map(option => (
              <MenuItem key={option.code} value={option.code} sx={CustomTradingMenuItemProps}>
                {option.display.toLocaleUpperCase()}
              </MenuItem>
            ))}
          </TradingSelect>
        )}
      </Stack>
      <TradingSelect
        size="small"
        value={tradingForm.selectedExchange}
        onChange={(event: any) => onExchangeChange(event.target.value)}
        MenuProps={CustomTradingMenuProps}
        tabIndex={-1}
      >
        {Object.values(OtcExchange).map(option => (
          <MenuItem key={option} value={option} sx={CustomTradingMenuItemProps}>
            {option.toLocaleUpperCase()}
          </MenuItem>
        ))}
      </TradingSelect>
      <Stack alignItems="center">
        <NumberInput
          ref={sizeInputRef}
          label="RFQ Lots"
          value={tradingForm.tradeSize}
          min={MIN_SIZE_VALUE}
          onChange={onTradeSizeChange}
          tabIndex={1}
          error={showUserInputError}
          allowClearable
          sizeSmall
          sx={{ width: '100%' }}
        />
        {isTradeLimitBreached && (
          <Typography fontSize={11} color="error" tabIndex={-1}>
            Trade size exceeds trading limit
          </Typography>
        )}
      </Stack>
      {subscriptionSymbol && subscriptionSize ? (
        <TradingButtons
          rfqSubscriptionSymbol={subscriptionSymbol}
          rfqSubscriptionSize={subscriptionSize}
          isDisabled={showUserInputError}
          tradingAccount={incomingSelectedTradingAccount}
        />
      ) : (
        <CircularProgress size={10} />
      )}
    </Stack>
  );
};
