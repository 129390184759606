import { useAdminApi } from '@hooks/useAdminApi';
import { useApi } from '@hooks/useApi';
import { styled } from '@mui/material';
import { Side } from '@protos/trading';
import { addSecondsToNow } from '@utils/date';
import { CustomCellEditorProps, useGridCellEditor } from 'ag-grid-react';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useLaddersApi } from '../hooks/useLaddersApi';

const EditorInput = styled('input')({
  width: '100%',
  height: '100%',
  padding: '0 8px',
  backgroundColor: 'white',
  color: 'black',
  border: 'none',
  outline: 'none',
  textAlign: 'center',
  fontSize: '12px',
});

interface MixedGridCellEditorProps extends CustomCellEditorProps {
  settings: {
    symbol: string;
    validity: number;
    size: number;
  };
  selectedAccount: string;
  gridId: string;
  midPriceValidity: string;
}

const isCharNumeric = (charStr: string) => {
  return !!/^-|\d|\./.test(charStr);
};

const isValidNumber = (value: string) => {
  if (value === '') return true;
  return /^-?\d*\.?\d*$/.test(value);
};

export const MixedGridCellEditor = memo((props: MixedGridCellEditorProps) => {
  const [value, setValue] = useState(props.value);
  const inputRef = useRef<HTMLInputElement>(null);
  const { apiClient } = useAdminApi();
  const { apiClient: client } = useApi();
  const { createLadder } = useLaddersApi();

  useEffect(() => {
    let startValue: string;
    if (props.eventKey === 'Backspace') {
      startValue = '';
    } else if (props.eventKey && props.eventKey.length === 1 && isCharNumeric(props.eventKey)) {
      startValue = props.eventKey;
      props.onValueChange(startValue);
    } else {
      startValue = value;
    }
    setValue(startValue);

    inputRef.current?.focus();
    inputRef.current?.select();
  }, []);

  const updateValue = useCallback(
    async (val: string) => {
      if (val === '') return;

      const currentField = props.column.getColId();
      const value = currentField === 'value' ? val : props.data.value;
      const spread = currentField === 'spread' ? val : props.data.spread;
      const size = currentField === 'size' ? val : props.data.size;

      if (!value || !spread || !size) return;

      try {
        await createLadder({
          symbol: `${props.settings.symbol}${props.data.tenorId}`,
          expiry_timestamp: addSecondsToNow(props.settings.validity),
          orders: [
            {
              price: Number(value) - Number(spread / 2),
              side: Side.BUY,
              amount: Number(size),
              hidden: false,
            },
            {
              price: Number(value) + Number(spread / 2),
              side: Side.SELL,
              amount: Number(size),
              hidden: false,
            },
          ],
          account_id: props.selectedAccount,
        });

        const newMid = {
          symbol: `${props.settings.symbol}${props.data.tenorId}`,
          price: props.data.value,
          expiry_timestamp: addSecondsToNow(Number(props.midPriceValidity)),
          is_live: true,
          created_timestamp: new Date().toISOString(),
        };

        await apiClient?.createMidPrice(newMid);

        const currentSettings = await client?.getUserSettings();
        await client?.updateUserSettings({
          dashboard: currentSettings?.dashboard ?? {},
          widgets: currentSettings?.widgets ?? {},
          ocl_trading: {
            ...currentSettings?.ocl_trading,
            mixed: {
              mixedGridData: {
                ...currentSettings?.ocl_trading?.mixed?.mixedGridData,
                [props?.gridId]: {
                  ...currentSettings?.ocl_trading?.mixed?.mixedGridData?.[props?.gridId],
                  [props.data.tenorId]: { value, spread, size },
                },
              },
            },
          },
        });

        setValue(val);
        props.onValueChange(val);
      } catch (error) {
        console.error(error);
        setValue(props.initialValue);
        props.onValueChange(props.initialValue);
      }
    },
    [apiClient, client, createLadder, props.selectedAccount]
  );

  useGridCellEditor({
    isCancelBeforeStart() {
      return false;
    },
    isCancelAfterEnd() {
      if (value !== props.initialValue) updateValue(value);
      return false;
    },
  });

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      if (isValidNumber(newValue)) {
        setValue(newValue);
        props.onValueChange(newValue);
      }
    },
    [props.onValueChange]
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (
        !isCharNumeric(event.key) &&
        !['Backspace', 'Delete', 'Tab', 'Enter', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(event.key)
      ) {
        event.preventDefault();
        return;
      }
    },
    [props.stopEditing]
  );

  return <EditorInput ref={inputRef} value={value ?? ''} onChange={onChange} onKeyDown={handleKeyDown} />;
});
