import { OtcQuoteSymbol } from '@protos/v2/otcQuote';
import { streamV2RFQ } from '@services/context';
import { QuoteCallback } from '@services/v2/streamV2RFQService';
import { useCallback, useEffect, useRef } from 'react';

export const useStreamRFQV2 = (callback: QuoteCallback, symbol: OtcQuoteSymbol, size: string, exchange: string) => {
  const currentSubscribedSymbol = useRef<OtcQuoteSymbol>();
  const currentSubscribedSize = useRef<string>();
  const currentSubscribedExchange = useRef<string>();

  const unsubscribeSymbol = useCallback(() => {
    if (currentSubscribedSymbol.current && currentSubscribedExchange.current) {
      streamV2RFQ.unsubscribe(currentSubscribedSymbol.current, currentSubscribedExchange.current);
    }
  }, []);

  useEffect(() => {
    if (
      !symbol ||
      (currentSubscribedSymbol.current === symbol && currentSubscribedSize.current === size && currentSubscribedExchange.current === exchange)
    )
      return;

    currentSubscribedSymbol.current = symbol;
    currentSubscribedSize.current = size;
    currentSubscribedExchange.current = exchange;

    streamV2RFQ.subscribe(symbol, size, exchange, callback);
  }, [symbol, size, exchange, callback]);

  return { unsubscribeSymbol };
};
