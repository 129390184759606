import { streamV2Product } from '@services/context';
import { TickerCallback } from '@services/v2/streamV2ProductService';
import { useEffect, useRef } from 'react';

export const useStreamV2Product = (callback: TickerCallback, productsToSubscribe: string[]) => {
  const target = useRef<TickerCallback>(callback);
  const subscribingProducts = [...productsToSubscribe];

  useEffect(() => {
    target.current = callback;
    streamV2Product.subscribe(subscribingProducts, callback);

    return () => {
      streamV2Product.unsubscribe(subscribingProducts, callback);
    };
  }, [subscribingProducts]);
};
