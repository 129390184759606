import { Box, Divider, Typography } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-enterprise';

export const OrderInputCellRenderer = (params: ICellRendererParams) => {
  if (params.data.id === params.colDef?.headerName) return;

  const { bid, offer } = params.value ?? { bid: '', offer: '' };
  if (bid === '' && offer === '') return;

  return (
    <Box display="flex" alignItems="center" justifyContent="space-around" height="100%">
      <Typography sx={{ color: '#4189E8', fontSize: '11px', fontWeight: 600, width: '45%' }}>
        {bid !== '' ? parseFloat(bid).toFixed(2) : ''}
      </Typography>
      <Divider orientation="vertical" sx={{ mx: 1, bgcolor: 'rgba(1, 1, 1, 0.25)' }} />
      <Typography sx={{ color: '#CD4B4B', fontSize: '11px', fontWeight: 600, width: '45%' }}>
        {offer !== '' ? parseFloat(offer).toFixed(2) : ''}
      </Typography>
    </Box>
  );
};
