import { EventsAlertsDialog } from '@features/admin/alerts/EventsAlertsDialog';
import { EditProductDialog } from '@features/admin/products/components/EditProductDialog';
import { ProductInfo } from '@features/admin/products/components/ProductInfo';
import { SpecificationInfo } from '@features/admin/products/components/SpecificationInfo';
import { useProductPage } from '@features/admin/products/hooks/useProductPage';
import { Box, Button, Paper, Typography } from '@mui/material';
import { Alert } from '@protos/alerts';
import { ADMIN_PRODUCT_ALERTS_COLUMNS, Product } from '@protos/product';
import { BlotterCard } from '@shared/components/BlotterCard';
import { DagGraph } from '@shared/components/DagGraph/DagGraph';
import { transformDagData } from '@shared/components/DagGraph/dagGraphUtils';
import { useUserContext } from '@shared/contexts/UserContextProvider';
import { usePageTitle } from '@shared/hooks/usePageTitle';
import { RowClickedEvent } from 'ag-grid-enterprise';
import { useCallback, useEffect, useState } from 'react';

export const ProductPage = () => {
  usePageTitle('Product details');
  const user = useUserContext();

  const { product, symbol, alertsData, isLoadingBlotters, ProductContractsBlotters, ProductInfoBlotters, pricingDagData } = useProductPage();

  const [selectedEventsAlert, setSelectedEventsAlert] = useState<Alert | undefined>();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [currentProduct, setCurrentProduct] = useState<Product | undefined>(product);

  const onAlertRowClicked = useCallback(
    (row: RowClickedEvent<Alert>) => {
      setSelectedEventsAlert(row.data as Alert);
    },
    [setSelectedEventsAlert]
  );

  const onProductChange = useCallback(
    (product: Product) => {
      setCurrentProduct(product);
    },
    [setCurrentProduct]
  );

  useEffect(() => {
    if (product) {
      setCurrentProduct(product);
    }
  }, [product]);

  if (!product || !symbol) return null;

  return (
    <>
      <Box display="flex" flexDirection="column" flex={1} padding={2} gap={2}>
        <Paper>
          <Box display="flex" justifyContent="space-between" flex={1} alignItems="end">
            <Box display="flex" flexDirection="column" padding={2} paddingBottom={0} flex={1}>
              <Typography variant="h3">{currentProduct?.symbol}</Typography>
              <Typography variant="h5" color="gray" marginTop={-1}>
                {currentProduct?.full_name}
              </Typography>
            </Box>
            <Button
              variant="outlined"
              size="small"
              onClick={() => setIsEditDialogOpen(true)}
              style={{ maxHeight: 30, marginRight: 16, fontSize: 11 }}
              disabled={!user?.is_flux_admin}
            >
              Edit Product
            </Button>
          </Box>
          <Box display="flex" flexDirection="column" gap={2} padding={2} sx={{ width: '100%' }}>
            <ProductInfo product={currentProduct || product} onProductUpdated={onProductChange} />
            {currentProduct?.specification && <SpecificationInfo specification={currentProduct.specification} />}
          </Box>
          <Box display="flex" flexWrap="wrap" flex={1} gap={2} margin={2} justifyContent="stretch">
            {ProductInfoBlotters.map(({ ...blotter }) => {
              return <BlotterCard key={blotter.title} {...blotter} />;
            })}
          </Box>
        </Paper>
        {isLoadingBlotters ? (
          <Paper>
            <Box display="flex" flex={1} justifyContent="center" alignContent="center" padding={10}>
              Loading more info...
            </Box>
          </Paper>
        ) : (
          <>
            {alertsData.length > 0 && (
              <>
                <Paper>
                  <Box display="flex" flex={1} justifyContent="stretch">
                    <BlotterCard
                      title="Alerts"
                      data={alertsData}
                      columns={ADMIN_PRODUCT_ALERTS_COLUMNS}
                      fullWidth
                      domLayout="autoHeight"
                      onRowClicked={onAlertRowClicked}
                    />
                  </Box>
                </Paper>
                {selectedEventsAlert && (
                  <EventsAlertsDialog selectedEventsAlert={selectedEventsAlert} onClose={() => setSelectedEventsAlert(undefined)} />
                )}
              </>
            )}
            <Paper>
              <Box display="flex" flexWrap="wrap" flex={1} gap={2} margin={2} justifyContent="stretch">
                {ProductContractsBlotters.map(({ ...blotter }) => {
                  return <BlotterCard key={blotter.title} {...blotter} fullWidth />;
                })}
              </Box>
            </Paper>
            <DagGraph data={transformDagData(pricingDagData)} />
          </>
        )}
      </Box>
      <EditProductDialog
        product={currentProduct || product}
        isOpen={isEditDialogOpen}
        onClose={() => setIsEditDialogOpen(false)}
        onProductUpdated={onProductChange}
      />
    </>
  );
};
