import CancelIcon from '@mui/icons-material/Cancel';
import { IconButton } from '@mui/material';
import { Order, OrderState } from '@protos/trading';
import theme from '@shared/themes/darkTheme';
import { ICellRendererParams } from 'ag-grid-community';
import { useLaddersApi } from '../hooks/useLaddersApi';

export const CancelOrderCellRenderer = (props: ICellRendererParams<Order> & { accountId: string }) => {
  const { expireLadder } = useLaddersApi();
  const isNotLive = props.node.data?.order_state === OrderState.EXPIRED || props.node.data?.order_state === OrderState.CANCELLED;

  const handleCancel = async () => {
    const symbol = typeof props.data?.symbol === 'string' ? props.data.symbol : `${props.data?.symbol['front']}-${props.data?.symbol['back']}`;
    await expireLadder(symbol, props.accountId);
  };

  return (
    <IconButton
      color="error"
      onClick={handleCancel}
      disabled={isNotLive}
      sx={{
        marginBottom: '2px',
        height: '15px',
        lineHeight: 1,
      }}
    >
      <CancelIcon
        sx={{
          fontSize: 15,
          padding: 0,
          color: theme.palette.background.sell,
          '&:hover': { color: 'white' },
          '.Mui-disabled &': { color: 'gray' },
        }}
      />
    </IconButton>
  );
};
