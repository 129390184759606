import { streamOrders } from '@services/context';
import { OrderCallback } from '@services/streamOrderService';
import { useEffect, useRef } from 'react';

export const useStreamOrders = (callback: OrderCallback, isReadyToSubscribe: boolean) => {
  const target = useRef<OrderCallback>(callback);

  useEffect(() => {
    if (!isReadyToSubscribe) return;

    target.current = callback;
    streamOrders.subscribe(callback);

    return () => {
      streamOrders.unsubscribe();
    };
  }, [isReadyToSubscribe, callback]);
};
