import { Product, ProductTenor } from './product';
import { OtcQuoteMessage } from './v2/otcQuote';

export type PriceAmount = {
  price: number;
  amount: number;
};

export type RFQPriceAmount = {
  price: string;
  amount: string;
};

export type Ticker = {
  symbol: string | { front: string; back: string };
  product_symbol: string;
  timestamp_millis: number;
  mid: string;
  bid?: PriceAmount;
  ask?: PriceAmount;
  full_price?: string;
};

export type RFQTicker = {
  symbol: string | { front: string; back: string };
  product_symbol: string;
  timestamp_millis: number;
  mid: string;
  bid?: RFQPriceAmount;
  ask?: RFQPriceAmount;
  exchange?: string;
};

export type Price = {
  price: string;
  amount: string;
  side: string;
  timestamp: string;
};

export type ChartTicker = {
  close: number;
  high: number;
  low: number;
  open: number;
  symbol: string;
  timestamp: string;
};

export type TickerMeta = {
  symbol: string;
  tenor: ProductTenor;
  product: Product;
  productSymbolRoot: string;
};

// Get the ticker meta data for mapping tickers to tenors and products
export const tickerMeta = (
  ticker: Ticker | OtcQuoteMessage,
  productMap: Record<string, Product>,
  tenorMap: Record<string, ProductTenor>
): TickerMeta | undefined => {
  const { symbol, product_symbol } = ticker;

  const tenorCode = (symbol as string).substring(product_symbol.length);
  const tenor = tenorMap[tenorCode];
  const product = productMap[product_symbol];

  if (!tenor || !product) return;

  let productSymbolRoot = product.symbol;

  if (product.product_type == 'calendar' && product.calendar_type !== 'spread') productSymbolRoot = product.underlying_symbol;
  else if (product.calendar_type === 'spread' && product.tenor_frequency === 'quarterly') {
    const tail = product.symbol.substring(product.underlying_symbol.length, product.symbol.length);
    const productName = product.underlying_symbol.substring(0, product.underlying_symbol.length - 1);
    productSymbolRoot = `${productName}${tail}`;
  }

  return { symbol: symbol as string, tenor, product, productSymbolRoot };
};

export const getAllRequestedContractSymbols = (
  allProducts: string[],
  allTenors: string[],
  productMap: Record<string, Product>,
  tenorMap: Record<string, ProductTenor>
): string[] => {
  const result = allProducts.reduce((acc, product) => {
    allTenors.forEach(tenor => {
      let contractSymbol = '';
      const detailedProduct = productMap[product];
      const detailedTenor = tenorMap[tenor];

      switch (detailedTenor.frequency) {
        case 'daily':
          contractSymbol = detailedProduct.calendar_type !== 'spread' ? `${detailedProduct.symbol}${detailedTenor.code}` : '';
          break;
        case 'weekly':
          contractSymbol = detailedProduct.calendar_type !== 'spread' ? `${detailedProduct.symbol}wk${detailedTenor.code}` : '';
          break;
        case 'monthly':
          contractSymbol =
            detailedProduct.calendar_type !== 'spread'
              ? `${detailedProduct.symbol}${detailedTenor.code}`
              : `${detailedProduct.symbol}${detailedTenor.spread_code}`;
          break;
        case 'quarterly':
          contractSymbol = detailedProduct.calendar_type !== 'spread' ? `${detailedProduct.symbol}q${detailedTenor.code}` : '';
          break;
        case 'yearly':
          contractSymbol = detailedProduct.calendar_type !== 'spread' ? `${detailedProduct.symbol}y${detailedTenor.code}` : '';
          break;
        default:
          contractSymbol = '';
          break;
      }

      if (contractSymbol) acc.push(contractSymbol);
    });

    return acc;
  }, [] as string[]);

  return result;
};
