import { GetRowIdParams } from 'ag-grid-enterprise';
import { useEffect } from 'react';
import { Grid } from '../../grid';
import { usePricesSettings } from '../PricesContext';
import { PricesRow } from '../types';
import { usePricesGrid } from '../usePricesGrid';

function getRowId({ data: { tenorId } }: GetRowIdParams<PricesRow>): string {
  return tenorId;
}

export default function PricesGrid() {
  const { columnDefs, columns, rows, filteredSelectedTenors, onColumnMoved, gridRef } = usePricesSettings();

  const {
    getRowStyle,
    getContextMenuItems,
    onGridCellClicked,
    isExternalFilterPresent,
    doesExternalFilterPass,
    getAllRows,
    selectedRowIds,
    onContextMenuVisibleChange,
  } = usePricesGrid(rows ?? [], filteredSelectedTenors);

  useEffect(() => {
    // After filters have been changed via API, we must ensure that the grid is refreshed;
    // If ´api.onFilterChanged()´ is not called, the grid will not be refreshed.
    if (gridRef?.current) {
      gridRef.current.getGridApi()?.onFilterChanged();
    }
  }, [selectedRowIds]);

  return (
    <Grid<PricesRow>
      ref={gridRef}
      defaultColDef={columnDefs}
      columnDefs={columns}
      rowData={getAllRows()}
      getRowId={getRowId}
      getContextMenuItems={getContextMenuItems}
      isExternalFilterPresent={isExternalFilterPresent}
      doesExternalFilterPass={doesExternalFilterPass}
      onColumnMoved={onColumnMoved}
      suppressRowVirtualisation
      suppressColumnVirtualisation
      getRowStyle={getRowStyle}
      onCellClicked={onGridCellClicked}
      onContextMenuVisibleChanged={onContextMenuVisibleChange}
      tooltipShowDelay={1000}
      tooltipInteraction={true}
      autoSizeStrategy={undefined}
      domLayout="autoHeight"
    />
  );
}
