import { FULLMO_TENOR_ID } from '@protos/grids';
import { IRowNode, RowClassParams } from 'ag-grid-enterprise';
import { useCallback, useMemo } from 'react';
import { usePricingSheetSettings } from '../PricingSheetContext';
import { FULLMO_TENOR_ROW } from '../utils';
import { PricesSheetRow } from './usePricingSheetGridState';

export function usePricingSheetGrid(rows: PricesSheetRow[], filteredSelectedTenors: any[]) {
  const { productMap } = usePricingSheetSettings();

  const FullMoRow = useMemo(() => {
    if (productMap) {
      const fullMoRow = { ...FULLMO_TENOR_ROW };
      Object.keys(productMap).forEach(val => {
        fullMoRow[val] = '';
      });

      return fullMoRow as PricesSheetRow;
    }
    return undefined;
  }, [productMap]);

  const getAllRows = useCallback(() => {
    if (rows && FullMoRow) {
      const BalMoIndex = rows.findIndex(row => row.tenorName.includes('BALMO'));
      if (BalMoIndex === -1) return rows;

      const allRows = [...rows];
      allRows.splice(BalMoIndex, 0, FullMoRow);
      return allRows;
    }

    return rows ?? [];
  }, [rows, FullMoRow]);

  const isFullMoRowPresentInBlotter = useMemo(() => {
    const allRows = getAllRows();
    if (FullMoRow) {
      return allRows?.some(row => row.tenorName.includes('FULLMO'));
    }

    return false;
  }, [getAllRows, FullMoRow]);

  const firstFrequencyRows = useMemo(() => {
    return filteredSelectedTenors.reduce((acc, tenor, index) => {
      if (index === 0) {
        const nextTenor = filteredSelectedTenors[index + 1];
        if (nextTenor.frequency !== tenor.frequency) {
          acc.push(tenor.code);
        }
      } else {
        const prevTenor = filteredSelectedTenors[index - 1];
        if (prevTenor.frequency !== tenor.frequency) {
          acc.push(tenor.code);
        }
      }

      return acc;
    }, [] as string[]);
  }, [filteredSelectedTenors]);
  const isJuneDecRow = (params: RowClassParams<PricesSheetRow, any>) => {
    return params.data?.tenorFrequency === 'monthly' && (params.data.tenorName.includes('JUN') || params.data.tenorName.includes('DEC'));
  };

  const isFirstFrequencyRow = useCallback(
    (params: RowClassParams<PricesSheetRow, any>) => {
      if (params.rowIndex === 0) return false;
      if (params.data?.tenorId === FULLMO_TENOR_ID) return true;

      if (isFullMoRowPresentInBlotter) {
        if (params.data?.tenorId && firstFrequencyRows.includes(params.data?.tenorId) && params.data?.tenorFrequency !== 'monthly') return true;
        return false;
      } else {
        if (params.data?.tenorId && firstFrequencyRows.includes(params.data?.tenorId)) return true;
        return false;
      }
    },
    [firstFrequencyRows, isFullMoRowPresentInBlotter]
  );

  const getRowStyle = useCallback(
    (params: RowClassParams<PricesSheetRow, any>) => {
      if (isFirstFrequencyRow(params)) {
        return { borderBottom: 'none', borderTop: '1px solid #f1c40f', ...(isJuneDecRow(params) ? { borderBottom: '1px solid grey' } : {}) };
      }

      if (isJuneDecRow(params)) {
        return { borderBottom: '1px solid grey', borderTop: '1px solid grey' };
      }

      return undefined;
    },
    [isFirstFrequencyRow, isJuneDecRow]
  );

  const selectedRowIds = useMemo(() => filteredSelectedTenors.map(elem => elem.code), [filteredSelectedTenors]);

  const isExternalFilterPresent = useCallback(() => selectedRowIds.length > 0, [selectedRowIds]);
  const doesExternalFilterPass = useCallback(
    (rowNode: IRowNode<PricesSheetRow>) => (rowNode.data ? [...selectedRowIds, FULLMO_TENOR_ID].includes(rowNode.data.tenorId) : true),
    [selectedRowIds]
  );

  return {
    getRowStyle,
    isExternalFilterPresent,
    doesExternalFilterPass,
    getAllRows,
    selectedRowIds,
  };
}
