import { useStreamOrders } from '@hooks/useStreamOrders';
import { FilterType } from '@protos/filter';
import { Order, OrderState, OrderType } from '@protos/trading';
import { priceFormatterService } from '@services/PriceFormatterService';
import { BlotterWidget } from '@shared/components/BotterWidget';
import { ColDef, GetRowIdParams, GridApi } from 'ag-grid-enterprise';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { ALL_ORDERS_GRID_COLUMNS } from '../constants/AllOrdersGridConsts';
import { useLaddersApi } from '../hooks/useLaddersApi';
import { CancelOrderCellRenderer } from './CancelOrderCellRenderer';

const formatOrder = (order: Order) => ({
  ...order,
  amount: priceFormatterService.removeTrailZeros(order.amount),
  executed_amount: order.executed_amount ? priceFormatterService.removeTrailZeros(order.executed_amount) : '',
  price: order.price ? order.price : '',
  executed_avg_price: order.executed_avg_price ? order.executed_avg_price : '',
});

interface AllOrdersGridProps {
  selectedAccount: string;
}

const FILTER_KEYS = {
  IS_LIVE: 'is_live',
  ACCOUNT_ID: 'account_id',
} as const;

export const AllOrdersGrid = React.memo(({ selectedAccount }: AllOrdersGridProps) => {
  const { getAllOrdersLoader } = useLaddersApi();

  const userSelectedAccount = useMemo(() => selectedAccount, [selectedAccount]);
  const [isReadyToSubscribe, setIsReadyToSubscribe] = useState<boolean>(false);
  const blotterApiRef = useRef<GridApi<Order> | null>(null);

  const cancelColumn: ColDef = {
    field: 'cancel',
    headerName: 'Cancel',
    width: 100,
    headerTooltip: 'Cancel',
    cellRenderer: CancelOrderCellRenderer,
    cellRendererParams: {
      accountId: userSelectedAccount,
    },
  };

  const columns = [...ALL_ORDERS_GRID_COLUMNS, cancelColumn];

  const dataLoader = getAllOrdersLoader();

  const onGetGridApi = useCallback((gridApi: GridApi<Order>) => {
    blotterApiRef.current = gridApi;
  }, []);
  const getRowId = useCallback((params: GetRowIdParams<Order>) => params.data?.id.toString(), []);

  const onDataRendered = useCallback(() => {
    setIsReadyToSubscribe(true);
  }, []);

  const allOrdersDataTransformer = useCallback((data: Order[]) => data, [userSelectedAccount]);

  const onStreamOrder = useCallback(
    (order: Order) => {
      const blotterApi = blotterApiRef.current;
      if (!blotterApi || order.order_type !== OrderType.LIMIT_MAKER || order.account_id !== userSelectedAccount) return;

      const node = blotterApi.getRowNode(order.id.toString());
      const isRowInGrid = !!node;
      if (isRowInGrid) {
        const transformedOrder = formatOrder(order);
        if (transformedOrder.order_state === OrderState.EXPIRED || transformedOrder.order_state === OrderState.CANCELLED) {
          node.setData(transformedOrder);
        }
        return;
      }

      const transformedOrder = formatOrder(order);

      blotterApi.applyTransactionAsync({ add: [transformedOrder] });

      const newRowNode = blotterApi.getRowNode(order.id.toString());
      if (newRowNode) {
        blotterApi.flashCells({ rowNodes: [newRowNode], fadeDuration: 2000 });
      }
    },
    [userSelectedAccount]
  );

  useStreamOrders(onStreamOrder, isReadyToSubscribe);

  if (!dataLoader) return null;

  const filtersTransformer = (urlParamFilters: { [x: string]: any }) => {
    //By default we always want to show the orders for the selected account and live orders
    const requiredFilters = {
      [FILTER_KEYS.ACCOUNT_ID]: userSelectedAccount,
      [FILTER_KEYS.IS_LIVE]: 'true',
    };

    //We don't want any other URL params to be used as filters
    const relevantUrlFilters = Object.fromEntries(
      Object.entries(urlParamFilters || {}).filter(([key]) => Object.values(FILTER_KEYS).some(allowedKey => key.includes(allowedKey)))
    );

    return {
      ...requiredFilters,
      ...relevantUrlFilters,
    };
  };

  return (
    <BlotterWidget
      filters={[{ type: FilterType.SWITCH, name: 'is_live', label: 'Is Live', defaultValue: 'true' }]}
      filtersTransformer={filtersTransformer}
      getRowId={getRowId}
      getGridApi={onGetGridApi}
      dataLoader={dataLoader}
      columnDefs={columns}
      maxHeight={500}
      autoSizeStrategy={{ type: 'fitCellContents' }}
      onFirstDataRendered={onDataRendered}
      dataTransformer={allOrdersDataTransformer}
    />
  );
});
