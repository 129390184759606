import { streamV2OrderBookTop } from '@services/context';
import { OrderBookTopCallback } from '@services/v2/streamV2OrderBookTopService';
import { useCallback, useEffect, useRef } from 'react';

export const useStreamV2OrderBookTopQuotes = (callback: OrderBookTopCallback, allProductsToBeSubscribed: string[]) => {
  const currentSubscribedSymbols = useRef<string[]>([]);

  const unsubscribeProducts = useCallback((products: string[]) => {
    const toUnsubscribe: string[] = [];

    products.forEach(product => {
      if (currentSubscribedSymbols.current?.includes(product)) {
        currentSubscribedSymbols.current = currentSubscribedSymbols.current?.filter(s => s !== product);
        toUnsubscribe.push(product);
      }
    });

    streamV2OrderBookTop.unsubscribe(toUnsubscribe, callback);
  }, []);

  const unsubscribeAllSymbols = useCallback(() => {
    unsubscribeProducts(currentSubscribedSymbols.current);
  }, [unsubscribeProducts]);

  useEffect(() => {
    const unsubscribedSymbols = currentSubscribedSymbols.current?.filter(s => !allProductsToBeSubscribed.includes(s));
    if (unsubscribedSymbols.length > 0) {
      unsubscribeProducts(unsubscribedSymbols);
    }

    allProductsToBeSubscribed.forEach(symbol => {
      if (currentSubscribedSymbols.current?.includes(symbol)) return;

      currentSubscribedSymbols.current?.push(symbol);
    });

    streamV2OrderBookTop.subscribe(allProductsToBeSubscribed, callback);
  }, [allProductsToBeSubscribed, callback, unsubscribeProducts]);

  return { unsubscribeProducts, unsubscribeAllSymbols };
};
