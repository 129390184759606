import { UserProfileForm } from '@features/user-account/manage/UserProfileForm';
import { Box } from '@mui/material';
import Alert, { StyledAlertLink } from '@shared/components/Alert';
import { UserContext } from '@shared/contexts/UserContextProvider';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

export const ProfilePage: React.FC = () => {
  const user = useContext(UserContext);

  const hasAnyWebClaim = true;

  if (!user) {
    return null;
  }

  return (
    <Box margin={2} paddingTop={2} marginTop={0}>
      {!hasAnyWebClaim && (
        <Alert error>
          You won't be able to access Flux because you are not added to a licence. Please{' '}
          <StyledAlertLink id="contact-us-no-licence-btn" component="button" color="error.main">
            <Link to="mailto:support@flux.live">Contact Support</Link>
          </StyledAlertLink>{' '}
          to find out how to be added to a licence, if you have not done so already.
        </Alert>
      )}
      <UserProfileForm user={user} />
    </Box>
  );
};
