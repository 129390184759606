import { Portal } from '@mui/material';
import { PhotoshopPicker } from 'react-color';
interface ColorPickerProps {
  onClose: () => void;
  onColorComplete: (color: { hex: string }) => void;
  color: string;
  onColorChange: (color: { hex: string }) => void;
}

export const ColorPicker = ({ onClose, onColorComplete, color, onColorChange }: ColorPickerProps) => {
  return (
    <Portal>
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: 'rgba(0, 0, 0, 0.5)',
          zIndex: 9999,
        }}
        onClick={e => {
          if (e.target === e.currentTarget) {
            onClose();
          }
        }}
      >
        <div onClick={e => e.stopPropagation()}>
          <PhotoshopPicker color={color} onChange={onColorChange} onAccept={onColorComplete} onCancel={onClose} />
        </div>
      </div>
    </Portal>
  );
};
