import Settings from '@features/dashboard/widgets/settings/Settings';
import { SettingsFormResultLayout } from '@features/dashboard/widgets/settings/SettingsFormResultLayout';
import { DEFAULT_ALLOWED_PRICING_TENORS, usePricesDefaultFilters } from '@features/dashboard/widgets/shared/hooks/usePricesDefaultFilters';
import { RollingRowSettings } from '@features/dashboard/widgets/shared/types';
import { Header } from '@features/dashboard/widgets/Widget';
import { useApi } from '@hooks/useApi';
import { Box, Divider } from '@mui/material';
import { useEffect, useState } from 'react';
import PricingSheetGrid from './components/PricingSheetGrid';
import { PricingSheetProductsSettingsForm } from './components/PricingSheetProductSettingsForm';
import { PricingSheetTenorsSettingsForm } from './components/PricingSheetTenorsSettingsForm';
import PricingSheetProvider from './PricingSheetContext';

export const OCLPricingSheet = ({ duration }: { duration: number }) => {
  const { apiClient } = useApi();
  const { DEFAULT_ALLOWED_PRICING_PRODUCTS } = usePricesDefaultFilters();
  const [isLoading, setIsLoading] = useState(true);

  const [initialValues, setInitialValues] = useState<{
    selectedColumns: string[];
    selectedRows: string[];
    columnsOrder: string[];
    userRollingRowSettings: RollingRowSettings;
    isOverrideRolling: boolean;
  }>({
    selectedColumns: DEFAULT_ALLOWED_PRICING_PRODUCTS,
    selectedRows: [],
    columnsOrder: [],
    userRollingRowSettings: DEFAULT_ALLOWED_PRICING_TENORS,
    isOverrideRolling: false,
  });

  useEffect(() => {
    if (!apiClient) return;
    const loadPricingSheetUserSettings = async () => {
      const settings = await apiClient.getUserSettings();
      if (settings?.ocl_trading?.pricingSheet) {
        const { selectedColumns, selectedRows, columnsOrder, userRollingRowSettings, isOverrideRolling } = settings.ocl_trading.pricingSheet;

        setInitialValues({
          selectedColumns: selectedColumns ?? DEFAULT_ALLOWED_PRICING_PRODUCTS,
          selectedRows: selectedRows ?? [],
          columnsOrder: columnsOrder ?? [],
          userRollingRowSettings: userRollingRowSettings ?? DEFAULT_ALLOWED_PRICING_TENORS,
          isOverrideRolling: isOverrideRolling ?? false,
        });
      }
      setIsLoading(false);
    };

    loadPricingSheetUserSettings();
  }, [apiClient]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Box display="block" width="100%" height="100%">
      <PricingSheetProvider initialValues={initialValues}>
        <Box display="flex" flexDirection="column" height="100%">
          <Header title="">
            <Settings id="standalone-prices-settings" widgetId="standalone-prices" isHideDeleteButton={true}>
              <Box display="flex" flex={1} flexDirection="row" gap={2}>
                <SettingsFormResultLayout title="Products" Form={<PricingSheetProductsSettingsForm />} />
                <Divider orientation="vertical" flexItem />
                <Box display="flex" flexDirection="column" flex={1}>
                  <SettingsFormResultLayout title="Tenors" Form={<PricingSheetTenorsSettingsForm />} />
                  <Divider orientation="horizontal" flexItem />
                </Box>
              </Box>
            </Settings>
          </Header>
          <Box flex={1}>
            <PricingSheetGrid validity={duration} />
          </Box>
        </Box>
      </PricingSheetProvider>
    </Box>
  );
};
