import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Collapse,
  Divider,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { IconComponent } from '@shared/components/IconComponent';
import { useUserProductsAndTenorsContext } from '@shared/contexts/UserProductsAndTenorsProvider';
import { Product } from '@shared/protos/product';
import React, { useCallback, useMemo, useState } from 'react';
import { usePricingSheetSettings } from '../PricingSheetContext';
import { MAXIMUM_PRODUCTS_LIMIT } from '../utils';

const icon = <IconComponent name="ri-checkbox-blank-line" />;
const checkedIcon = <IconComponent name="ri-checkbox-line" />;

const StyledList = styled(List)({
  boxSizing: 'content-box',
  padding: 0.5,
  overflow: 'auto',
  maxHeight: 600,
  marginTop: '10px !important',
  marginLeft: '0px !important',
  marginRight: '-4px !important',
  border: '1px solid #505050',
  boxShadow: '0px 2px 10px 2px rgba(0, 0, 0, 0.1)',
});

export const PricingSheetProductsSettingsForm = () => {
  const { userProducts, loaded } = useUserProductsAndTenorsContext();
  const { toggleColumn, selectedColIds, addColumn, selectedUserProducts } = usePricingSheetSettings();

  const allProducts = userProducts.filter(
    product => !product.underlying_symbol || (product.calendar_type === 'spread' && product.tenor_frequency !== 'quarterly')
  );
  const allProductGroups = useMemo(() => {
    const groups: { [key: string]: Product[] } = {};
    allProducts.forEach(product => {
      if (!groups[product.product_group]) {
        groups[product.product_group] = [];
      }
      groups[product.product_group].push(product);
    });
    return groups;
  }, [allProducts]);
  const allSelectedProducts = useMemo(() => allProducts.filter(elem => selectedColIds.includes(elem.symbol)), [selectedColIds, allProducts]);

  const [expandedGroups, setExpandedGroups] = useState<string[]>([]);
  const [searchInput, setSearchInput] = useState('');

  const filteredProductGroups = useMemo(() => {
    if (!searchInput) return allProductGroups;

    const filteredGroups: { [key: string]: Product[] } = {};

    Object.keys(allProductGroups).forEach(groupName => {
      const allMatchingProductsInGroup = allProductGroups[groupName].filter(
        product =>
          product.full_name.toLowerCase().includes(searchInput.toLowerCase()) || product.symbol.toLowerCase().includes(searchInput.toLowerCase())
      );

      if (allMatchingProductsInGroup.length) {
        filteredGroups[groupName] = allMatchingProductsInGroup;
      }
    });

    return filteredGroups;
  }, [searchInput, allProductGroups]);

  const onChangeValues = useCallback(
    (selectedValues: Product[] | null) => {
      const deltaValues = allSelectedProducts.filter(({ symbol }) => !selectedValues?.find(elem => elem.symbol === symbol));
      if (deltaValues.length) {
        deltaValues.forEach(removedValue => {
          toggleColumn(removedValue.symbol);
        });
      }

      if (selectedValues?.length && addColumn) {
        selectedValues.forEach(selectedValue => {
          addColumn(selectedValue.symbol);
        });
      }
    },
    [toggleColumn, addColumn, allSelectedProducts]
  );

  const onCheckboxChange = useCallback(
    (product: Product) => {
      const newSelectedValues = allSelectedProducts.find(elem => elem.symbol === product.symbol)
        ? allSelectedProducts.filter(({ symbol }) => symbol !== product.symbol)
        : [...allSelectedProducts, product];
      onChangeValues(newSelectedValues.map(({ symbol }) => allProducts.find(product => product.symbol === symbol)!));
    },
    [allSelectedProducts, onChangeValues, allProducts]
  );

  const onRemoveProduct = useCallback(
    (productSymbol: string) => {
      const newSelectedValues = allSelectedProducts.filter(({ symbol }) => symbol !== productSymbol);
      onChangeValues(newSelectedValues.map(({ symbol }) => allProducts.find(product => product.symbol === symbol)!));
    },
    [onChangeValues, allProducts]
  );

  const onToggleGroupClick = useCallback(
    (groupName: string) => {
      if (expandedGroups.includes(groupName)) {
        const idx = expandedGroups.findIndex(elem => elem === groupName);
        const newList = [...expandedGroups];
        newList.splice(idx, 1);
        setExpandedGroups(newList);
      } else {
        setExpandedGroups([...expandedGroups, groupName]);
      }
    },
    [expandedGroups]
  );

  return loaded && allProducts.length ? (
    <Stack direction="column" spacing={2}>
      {selectedUserProducts.length >= MAXIMUM_PRODUCTS_LIMIT && (
        <Typography textAlign="left" variant="body" sx={{ color: 'error.main' }}>
          You've reached maximum number of {MAXIMUM_PRODUCTS_LIMIT} products that can been selected, please remove a few.
        </Typography>
      )}

      <Box display="flex" flex={1}>
        <Box display="flex" flexWrap="wrap" flex={1} gap={1}>
          {allSelectedProducts.map(({ symbol, full_name }) => {
            return (
              <Chip key={`${symbol}-${full_name}`} label={full_name} onDelete={() => onRemoveProduct(symbol)} size="small" sx={{ fontSize: 10 }} />
            );
          })}
        </Box>
        <Button variant="outlined" size="small" onClick={() => onChangeValues([])} style={{ fontSize: 9, alignSelf: 'end' }}>
          Clear All
        </Button>
      </Box>

      <Divider>
        <Typography variant="caption" fontSize={9}>
          Add / Remove Products Below
        </Typography>
      </Divider>

      <TextField
        placeholder="Search products"
        size="small"
        value={searchInput}
        onChange={e => setSearchInput(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon sx={{ fontSize: 15 }} />
            </InputAdornment>
          ),
        }}
        fullWidth
        sx={{
          '& .MuiInputBase-input': {
            fontSize: 11,
          },
        }}
      />

      {Object.keys(filteredProductGroups).length ? (
        <StyledList>
          {Object.keys(filteredProductGroups).map((groupName, index) => (
            <Stack key={groupName}>
              <ListItemButton
                onClick={() => onToggleGroupClick(groupName)}
                sx={{ backgroundColor: '#585858', padding: '5px', paddingTop: 0, paddingBottom: 0 }}
              >
                <ListItemText
                  primary={groupName}
                  primaryTypographyProps={{
                    style: {
                      fontSize: 11,
                    },
                  }}
                />
                {expandedGroups.includes(groupName) ? <ExpandLess sx={{ fontSize: 17 }} /> : <ExpandMore sx={{ fontSize: 17 }} />}
              </ListItemButton>
              <Collapse in={expandedGroups.includes(groupName)} timeout="auto" unmountOnExit>
                <List
                  component="div"
                  disablePadding
                  sx={{ borderLeft: '1px solid #505050', borderRight: '1px solid #505050', marginLeft: 0.1, marginRight: 0.1 }}
                >
                  {filteredProductGroups[groupName].map((product, index) => (
                    <React.Fragment key={`${product.symbol}-${groupName}`}>
                      <ListItem key={product.symbol} disableGutters sx={{ padding: 0 }}>
                        <ListItemIcon>
                          <Checkbox
                            size="small"
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={allSelectedProducts.find(elem => elem.symbol === product.symbol) !== undefined}
                            onChange={() => onCheckboxChange(product)}
                          />
                        </ListItemIcon>
                        <ListItemText primary={<Typography fontSize={11}>{product.full_name}</Typography>} />
                        <Typography variant="caption" fontSize={11} sx={{ textAlign: 'end', marginRight: 1 }}>
                          {product.symbol.toLocaleUpperCase()}
                        </Typography>
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  ))}
                </List>
              </Collapse>
              {index !== Object.keys(filteredProductGroups).length - 1 && <Divider />}
            </Stack>
          ))}
        </StyledList>
      ) : (
        <Typography textAlign="center" variant="body">
          No products found
        </Typography>
      )}
    </Stack>
  ) : null;
};
