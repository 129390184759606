import { useApi } from '@hooks/useApi';
import { CellStyle } from '@pages/ocl-trading/types/types';
import { TENOR_COL_ID } from '@protos/grids';
import { UserSettings } from '@protos/user';
import { CellRange, GetContextMenuItemsParams, GridApi, MenuItemDef } from 'ag-grid-enterprise';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

interface ActiveColorKey {
  cellKey: string;
  ranges: CellRange[] | undefined;
  type: 'background' | 'color' | 'border';
  api: GridApi;
}

export const usePricesGridSheet = (editableSymbols: string[]) => {
  const { apiClient } = useApi();

  const [cellStyles, setCellStyles] = useState<Record<string, CellStyle>>({});
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false);
  const [activeColorKey, setActiveColorKey] = useState<ActiveColorKey>();
  const [tempColor, setTempColor] = useState<string>();

  const handleColorChange = useCallback((color: { hex: string }) => {
    setTempColor(color.hex);
  }, []);

  const handleColorComplete = useCallback(() => {
    if (activeColorKey && tempColor) {
      const styleKey = activeColorKey.type === 'background' ? 'backgroundColor' : activeColorKey.type === 'color' ? 'color' : 'borderColor';

      activeColorKey.ranges?.forEach(range => {
        const columns = range.columns;
        const startRow = Math.min(range?.startRow?.rowIndex ?? 0, range?.endRow?.rowIndex ?? 0);
        const endRow = Math.max(range?.startRow?.rowIndex ?? 0, range?.endRow?.rowIndex ?? 0);

        for (let rowIdx = startRow; rowIdx <= endRow; rowIdx++) {
          const rowNode = activeColorKey.api.getDisplayedRowAtIndex(rowIdx);
          if (rowNode) {
            columns.forEach(col => {
              const colId = col.getColId();
              const cellKey = `${rowNode.data.tenorId}-${colId}`;
              updateCellStyle(cellKey, {
                [styleKey]: tempColor,
              });
            });
          }
        }
      });
    }

    setShowColorPicker(false);
    setTempColor(undefined);
  }, [activeColorKey, editableSymbols, tempColor]);

  const handleResetColors = useCallback(
    (ranges: any[], api: any) => {
      ranges?.forEach(range => {
        const columns = range.columns;
        const startRowIndex = range.startRow?.rowIndex ?? 0;
        const endRowIndex = range.endRow?.rowIndex ?? 0;
        const startRow = Math.min(startRowIndex, endRowIndex);
        const endRow = Math.max(startRowIndex, endRowIndex);

        for (let rowIdx = startRow; rowIdx <= endRow; rowIdx++) {
          const rowNode = api.getDisplayedRowAtIndex(rowIdx);
          if (rowNode) {
            columns.forEach(col => {
              const colId = col.getColId();
              const cellKey = `${rowNode.data.tenorId}-${colId}`;
              updateCellStyle(cellKey, {
                backgroundColor: undefined,
                color: undefined,
                borderColor: undefined,
              });
            });
          }
        }
      });
    },
    [editableSymbols]
  );

  const getStandaloneContextMenuItems = useCallback(
    (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
      const colId = params.column?.getColId();
      const result: (string | MenuItemDef)[] = ['copy'];

      if (colId !== TENOR_COL_ID) {
        const ranges = params.api.getCellRanges();
        const cellKey = `${params.node?.data.tenorId}-${colId}`;
        result.push({
          name: 'Cell Appearance',
          subMenu: [
            {
              name: 'Background Color',
              action: () => {
                setActiveColorKey({ cellKey, ranges: ranges || [], type: 'background', api: params.api });
                setShowColorPicker(true);
              },
            },
            {
              name: 'Border Color',
              action: () => {
                setActiveColorKey({ cellKey, ranges: ranges || [], type: 'border', api: params.api });
                setShowColorPicker(true);
              },
            },
            {
              name: 'Font Color',
              action: () => {
                setActiveColorKey({ cellKey, ranges: ranges || [], type: 'color', api: params.api });
                setShowColorPicker(true);
              },
            },
            {
              name: 'Reset Colors',
              action: () => {
                handleResetColors(ranges || [], params.api);
              },
            },
          ],
        });
      }
      return result;
    },
    [editableSymbols]
  );

  const getUserSettings = useCallback(async () => {
    if (!apiClient) return;

    const settings = await apiClient.getUserSettings();
    return settings;
  }, [apiClient]);

  useEffect(() => {
    const loadUserSettings = async () => {
      const userSettings = await getUserSettings();
      if (!userSettings) return;

      const pricingSheet = userSettings.ocl_trading?.pricingSheet;
      if (!pricingSheet || !pricingSheet.pricesGridCellStyles) return;

      setCellStyles(pricingSheet.pricesGridCellStyles);
    };

    loadUserSettings();
  }, [getUserSettings]);

  useEffect(() => {
    if (!apiClient) return;

    const saveUpdatedStyles = debounce(async (updatedStyleSettings: Record<string, CellStyle>, userSettings: UserSettings) => {
      await apiClient.updateUserSettings({
        ...userSettings,
        ocl_trading: {
          ...userSettings.ocl_trading,
          pricingSheet: {
            ...userSettings.ocl_trading?.pricingSheet,
            pricesGridCellStyles: updatedStyleSettings,
          },
        },
      });
    }, 1000);

    const loadUserSettings = async () => {
      const userSettings = await getUserSettings();
      if (!userSettings) return;

      saveUpdatedStyles(cellStyles, userSettings);
    };

    loadUserSettings();

    return () => saveUpdatedStyles.cancel();
  }, [cellStyles, apiClient]);

  const updateCellStyle = useCallback((cellKey: string, style: Partial<CellStyle>) => {
    setCellStyles(prev => ({
      ...prev,
      [cellKey]: { ...prev[cellKey], ...style },
    }));
  }, []);

  const standaloneProps = {
    cellStyles,
    updateCellStyle,
  };

  return {
    standaloneProps,
    showColorPicker,
    setShowColorPicker,
    activeColorKey,
    handleColorChange,
    handleColorComplete,
    tempColor,
    setTempColor,
    getStandaloneContextMenuItems,
  };
};
