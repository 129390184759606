import { Product, ProductTenor } from '@shared/protos/product';
import { ColDef } from 'ag-grid-enterprise';
import FlashingCellRenderer from '../shared/ag-grid/FlashingCellRenderer';
import { getBalDisplayName } from '../shared/utils';
import { BidOfferType, PricesRow } from './types';

const TENOR_COL_ID = 'tenorId';

export const tenorColumn: ColDef<PricesRow> = {
  field: 'tenorName',
  colId: TENOR_COL_ID,
  headerName: '',
  suppressSpanHeaderHeight: true,
  minWidth: 80,
  maxWidth: 120,
  lockPosition: 'left',
  pinned: 'left',
  cellClass: 'ag-tenor-column',
  resizable: true,
};

export const mapColumns = (
  products: Product[],
  selectedColumns: string[],
  columnsOrder: string[],
  isMobile: boolean,
  selectedBidOfferColumns: BidOfferType[]
): ColDef<any, any>[] =>
  products
    .sort((a, b) => columnsOrder.indexOf(`${a.symbol}-mid`) - columnsOrder.indexOf(`${b.symbol}-mid`))
    .reduce<ColDef<any, any>[]>(
      (accumulator, { symbol, short_name, full_name }, colIndex) => [
        ...accumulator,
        {
          colId: symbol,
          headerName: short_name,
          headerTooltip: full_name,
          hide: !selectedColumns.includes(symbol),
          resizable: !isMobile,
          marryChildren: true,
          children: [
            {
              colId: `${symbol}-bid`,
              headerName: 'Bid',
              cellRenderer: FlashingCellRenderer,
              valueGetter: (params: any) => params.data?.[symbol]?.buy,
              valueSetter: (params: any) => {
                if (!params.data[symbol]) {
                  params.data[symbol] = { buy: null, mid: null, sell: null };
                  return false;
                }

                params.data[symbol].buy = params.newValue;
                return true;
              },
              flex: 1,
              minWidth: !isMobile ? 50 : 90,
              cellClass: (params: any) => (params.data?.[symbol]?.buy != 0 ? 'teal-cell' : ''),
              cellClassRules: {
                'ag-col-group-even': () => colIndex % 2 === 0,
              },
              hide: !selectedBidOfferColumns.includes(BidOfferType.Bid),
            },
            {
              colId: `${symbol}-mid`,
              headerName: 'Mid',
              valueGetter: (params: any) => params.data?.[symbol]?.mid,
              valueSetter: (params: any) => {
                if (!params.data[symbol]) {
                  params.data[symbol] = { buy: null, mid: null, sell: null };
                  return false;
                }

                params.data[symbol].mid = params.newValue;
                return true;
              },
              flex: 1,
              minWidth: !isMobile ? 50 : 90,
              cellClassRules: {
                'ag-col-group-even': () => colIndex % 2 === 0,
              },
            },
            {
              colId: `${symbol}-offer`,
              headerName: 'Offer',
              cellRenderer: FlashingCellRenderer,
              valueGetter: (params: any) => params.data?.[symbol]?.sell,
              valueSetter: (params: any) => {
                if (!params.data[symbol]) {
                  params.data[symbol] = { buy: null, mid: null, sell: null };
                  return false;
                }

                params.data[symbol].sell = params.newValue;
                return true;
              },
              flex: 1,
              minWidth: !isMobile ? 50 : 90,
              cellClass: (params: any) => (params.data?.[symbol]?.sell != 0 ? 'teal-cell' : ''),
              cellClassRules: {
                'ag-col-group-even': () => colIndex % 2 === 0,
              },
              hide: !selectedBidOfferColumns.includes(BidOfferType.Offer),
            },
          ],
        },
      ],
      [tenorColumn]
    );

export const mapRows = (tenors: ProductTenor[], products: Product[], firstWeekIndex: number, firstMonthIndex: number): PricesRow[] => {
  return tenors.map(({ code, display, frequency }, index) => {
    const newRow = {
      tenorId: code,
      tenorName: getBalDisplayName(firstMonthIndex, firstWeekIndex, index, display),
      tenorFrequency: frequency,
    };
    products.forEach(({ symbol }) => {
      newRow[symbol] = { mid: '', buy: '', sell: '' };
    });

    return newRow as PricesRow;
  });
};
