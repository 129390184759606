import { useStreamRFQV2 } from '@hooks/useStreamRFQV2';
import { OtcExchange, OtcQuote, OtcQuoteMessage } from '@protos/v2/otcQuote';
import { ReactNode, createContext, useCallback, useContext, useMemo, useState } from 'react';

export type TradingPanel = {
  selectedProduct: string;
  selectedTenor: string | { left: string; right: string };
  tradeSize: number;
  selectedTradingAccount: string;
  selectedExchange: OtcExchange;
};

type TradingPanelState = {
  prices: { buy: OtcQuote | undefined; sell: OtcQuote | undefined };
  isLoadingPrices: boolean;
  subscriptionSymbol: string;
  subscriptionExchange: OtcExchange;
  setSubscriptionSymbol: (symbol: string) => void;
  setSubscriptionExchange: (exchange: OtcExchange) => void;
  setIsLoadingPrices: (isLoading: boolean) => void;
  setPrices: (prices: { buy: OtcQuote | undefined; sell: OtcQuote | undefined }) => void;
  subscriptionSize: number;
  setSubscriptionSize: (size: number) => void;
  unsubscribeSymbol: () => void;
};

type Props = {
  children: ReactNode;
};

const TradingContext = createContext<TradingPanelState | undefined>(undefined);
TradingContext.displayName = 'TradingContext';

export default function TradingProvider({ ...otherProps }: Props) {
  const [prices, setPrices] = useState<{ buy: OtcQuote | undefined; sell: OtcQuote | undefined }>({ buy: undefined, sell: undefined });
  const [isLoadingPrices, setIsLoadingPrices] = useState<boolean>(true);
  const [subscriptionSymbol, setSubscriptionSymbol] = useState<string>('');
  const [subscriptionSize, setSubscriptionSize] = useState<number>(1);
  const [subscriptionExchange, setSubscriptionExchange] = useState<OtcExchange>(OtcExchange.ice);

  const rfqCallback = useCallback(
    (incomingQuoteMessage: OtcQuoteMessage) => {
      const { symbol, product_symbol } = incomingQuoteMessage;

      if (incomingQuoteMessage.buy && incomingQuoteMessage.sell) {
        const rfqSymbolKey = typeof symbol === 'string' ? `${product_symbol}${symbol.split(product_symbol)[1]}` : `${symbol.front}-${symbol.back}`;

        const isValidSymbol = rfqSymbolKey === subscriptionSymbol;

        if (isValidSymbol) {
          setPrices({ buy: incomingQuoteMessage.buy, sell: incomingQuoteMessage.sell });
          setIsLoadingPrices(false);
        }
      }
    },
    [subscriptionSymbol]
  );

  const { unsubscribeSymbol } = useStreamRFQV2(rfqCallback, subscriptionSymbol, subscriptionSize.toString(), subscriptionExchange);

  const value: TradingPanelState = useMemo(
    () => ({
      prices,
      subscriptionSymbol,
      subscriptionSize,
      subscriptionExchange,
      isLoadingPrices,
      setIsLoadingPrices,
      setSubscriptionSymbol,
      setSubscriptionSize,
      setSubscriptionExchange,
      setPrices,
      unsubscribeSymbol,
    }),
    [
      subscriptionSymbol,
      subscriptionSize,
      subscriptionExchange,
      isLoadingPrices,
      prices,
      unsubscribeSymbol,
      setSubscriptionSymbol,
      setSubscriptionSize,
      setSubscriptionExchange,
      setPrices,
    ]
  );

  return <TradingContext.Provider value={value} {...otherProps} />;
}

export function useTradingContext(): TradingPanelState {
  const context = useContext(TradingContext);

  if (!context) {
    throw new Error('useTradingContext must be used within a TradingProvider');
  }

  return context;
}
