import { User } from '@protos/user';
import { useEffect, useMemo, useState } from 'react';

export const useTradingPermissions = (user: User | undefined, payloadValue?: string) => {
  const [selectedTradingAccount, setSelectedTradingAccount] = useState<string | undefined>(undefined);

  const tradingAccounts = useMemo(() => {
    if (!user) return [];
    return user.abn_trad_accounts;
  }, [user]);

  const userTradeLimits = useMemo(() => {
    if (!user) return {};
    return user.trade_limits.reduce((acc, curr) => {
      acc[curr.symbol] = curr.limit;
      return acc;
    }, {} as Record<string, number>);
  }, [user]);

  useEffect(() => {
    const defaultTradingAccount = tradingAccounts[0];

    setSelectedTradingAccount(payloadValue || defaultTradingAccount);
  }, [tradingAccounts]);

  return {
    tradingAccounts,
    userTradeLimits,
    selectedTradingAccount,
    setSelectedTradingAccount,
  };
};
