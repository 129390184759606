import { useScreenSize } from '@hooks/useScreenSize';
import { Box, Link, Stack, Typography } from '@mui/material';
import TeamPhoto from '../shared/assets/FluxTeamPhoto.png';

const getImageWidthHeight = (isMobile: boolean, isMobileLandscape: boolean): { width: number; height: number } => {
  if (isMobile && !isMobileLandscape) {
    return { width: 388.8, height: 162 };
  } else if (isMobileLandscape) {
    return { width: 576, height: 240 };
  }
  return { width: 1152, height: 480 };
};

const FoundingFathers: { name: string; linkedIn: string }[] = [
  { name: 'Luca Sbardella', linkedIn: 'https://www.linkedin.com/in/lucasbardella/' },
  { name: 'Shaurya Puri', linkedIn: 'https://www.linkedin.com/in/shaurya-puri/' },
  { name: 'Ishaan Hemnani', linkedIn: 'https://www.linkedin.com/in/ishaan-hemnani-60a02831/' },
  { name: 'Anthony Dang', linkedIn: 'https://www.linkedin.com/in/anthonydang9/' },
  { name: 'Rohit Mungre', linkedIn: 'https://www.linkedin.com/in/rohit-mungre-1a936928/' },
  { name: 'Mohit Sonania', linkedIn: 'https://www.linkedin.com/in/mohitsonania/' },
];

export const HallOfFame: React.FC = () => {
  const { isMobileLandscape, isMobile } = useScreenSize();
  const { width, height } = getImageWidthHeight(isMobile, isMobileLandscape);

  return (
    <Box
      display="flex"
      flex={1}
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      height="100%"
      minHeight="100vh"
      style={{ backgroundColor: 'black', color: 'white' }}
      {...(isMobile ? { paddingTop: '60%' } : {})}
    >
      <Stack mb={7}>
        <Typography variant="h6" align="center" fontFamily="monospace" fontWeight="bold">
          The Founding Fathers of Flux Pro
        </Typography>
        <Typography variant="caption" align="center" fontFamily="monospace">
          rome indeed was built in a year, b*tch
        </Typography>
      </Stack>

      <Box display="flex" flexDirection="column" alignItems="center">
        <img
          src={TeamPhoto}
          alt="Hall of Fame"
          width={width}
          height={height}
          style={{ borderRadius: 10, boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)' }}
        />
        <Stack mt={2} style={{ color: 'whitesmoke' }}>
          <Typography variant="caption" align="center" fontWeight="bold" fontFamily="monospace">
            {FoundingFathers.map((father, index) => (
              <>
                <Link href={father.linkedIn} target="_blank" rel="noopener noreferrer" style={{ color: 'inherit' }}>
                  {father.name}
                </Link>
                {index < FoundingFathers.length - 1 ? ' - ' : ''}
              </>
            ))}
          </Typography>
          <Typography variant="caption" align="center" fontFamily="monospace">
            & an amazing team at{' '}
            {
              <Link href="https://www.onyxcapitalgroup.com/" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit' }}>
                Onyx Capital Group
              </Link>
            }
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};
