import Settings from '@features/dashboard/widgets/settings/Settings';
import { SettingsFormResultLayout } from '@features/dashboard/widgets/settings/SettingsFormResultLayout';
import { MixedGridTenorsSettings } from '../Mixed/MixedGridTenorsSettings';
import { useSharedTenorSettings } from './SharedTenorSettingsContext';

export const SharedTenorSettings = () => {
  const settings = useSharedTenorSettings();

  return (
    <Settings id="shared-tenors" widgetId="shared" isHideDeleteButton={true} title="Tenors">
      <SettingsFormResultLayout
        title="Tenors"
        Form={
          <MixedGridTenorsSettings
            selectedTenors={settings.selectedTenors}
            rollingSettings={settings.rollingSettings}
            isOverrideRolling={settings.isOverrideRolling}
            onTenorToggle={(id: string) => settings.toggleTenor(id)}
            onRollingSettingsUpdate={settings.updateRollingSettings}
            onOverrideToggle={settings.toggleIsOverrideRolling}
          />
        }
      />
    </Settings>
  );
};
