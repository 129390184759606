import { RFQTicker } from '@shared/protos/ticker';
import { OtcOrder } from '@shared/protos/trading';
import { ChannelTypeV1, Stream, StreamEvent, Subscription } from './Stream';

export type TickerCallback = (ticker: RFQTicker) => void;

export const streamRFQService = (stream: Stream) => {
  const rfqStream: Record<string, TickerCallback> = {};

  stream.onEvent(ChannelTypeV1.RFQ, (event: StreamEvent) => {
    const tickers = event.asRFQTickers();
    if (!tickers) return;

    tickers.forEach(ticker => {
      const symbol = typeof ticker.symbol === 'string' ? ticker.symbol : `${ticker.symbol.front}-${ticker.symbol.back}`;
      const callback = rfqStream[`${symbol}-${ticker.bid?.amount}-${ticker.exchange}`];

      if (callback) {
        callback(ticker);
      }
    });
  });

  stream.onConnect(() => {
    const rfqs = Object.keys(rfqStream);
    if (rfqs.length > 0) {
      rfqs.forEach(rfq => {
        const symbolElements = rfq.split('-');
        if (symbolElements.length > 2) {
          const [frontSymbol, backSymbol, size, exchange] = symbolElements;
          stream.subscribe(Subscription.rfq({ rfqInfo: `${frontSymbol}-${backSymbol}-${size}`, exchange }));
        } else if (symbolElements.length === 2) {
          const [symbol, size, exchange] = symbolElements;
          stream.subscribe(Subscription.rfq({ rfqInfo: `${symbol}-${size}`, exchange }));
        }
      });
    }
  }, ChannelTypeV1.RFQ);

  const subscribe = (rfqSymbol: string, exchange: string, callback: TickerCallback) => {
    const symbolElements = rfqSymbol.split('-');
    if (symbolElements.length > 2) {
      const [frontSymbol, backSymbol, size] = symbolElements;
      const foundSimilarKey = Object.keys(rfqStream).find(key => key.startsWith(`${frontSymbol}-${backSymbol}`));
      if (foundSimilarKey) {
        delete rfqStream[foundSimilarKey];
      }

      rfqStream[`${frontSymbol}-${backSymbol}-${size}-${exchange}`] = callback;
    } else if (symbolElements.length === 2) {
      const [symbol, size] = symbolElements;
      const foundSimilarKey = Object.keys(rfqStream).find(key => key.startsWith(`${symbol}`));
      if (foundSimilarKey) {
        delete rfqStream[foundSimilarKey];
      }

      rfqStream[`${symbol}-${size}-${exchange}`] = callback;
    }

    stream.subscribe(Subscription.rfq({ rfqInfo: rfqSymbol, exchange }));
  };

  const unsubscribe = (rfqSymbol: string, exchange: string) => {
    const symbolElements = rfqSymbol.split('-');
    if (symbolElements.length > 2) {
      const [frontSymbol, backSymbol, size] = symbolElements;
      delete rfqStream[`${frontSymbol}-${backSymbol}-${size}-${exchange}`];
    } else if (symbolElements.length === 2) {
      const [symbol, size] = symbolElements;
      delete rfqStream[`${symbol}-${size}-${exchange}`];
    }

    stream.unsubscribe(Subscription.rfq({ rfqInfo: rfqSymbol, exchange }));
  };

  const placeOrder = (order: OtcOrder) => {
    stream.placeOtcOrder(order);
  };

  return {
    subscribe,
    placeOrder,
    unsubscribe,
  };
};
