import { useAdminApi } from '@hooks/useAdminApi';
import { Paper } from '@mui/material';
import { User } from '@protos/user';
import InlineGroup from '@shared/components/InlineGroup';
import MultilineGroup from '@shared/components/MultilineGroup';
import { TextField } from '@shared/components/TextField';
import { TextInputSelectBox } from '@shared/components/TextFieldSelectBox';
import { TextInputReadOnlyBox } from '@shared/components/TextInputReadOnlyBox';
import { useUserContext } from '@shared/contexts/UserContextProvider';
import { useScreenSize } from '@shared/hooks/useScreenSize';
import { PRODUCT_GROUP_NAME } from '@shared/protos/options';
import { Product } from '@shared/protos/product';
import theme from '@shared/themes/darkTheme';
import { chunkArray } from '@shared/utils/array';
import { ReactNode, useCallback } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

type Props = {
  product: Product;
  onProductUpdated: (product: Product) => void;
};

const getBooleanStyles = (value: boolean) => ({
  '& .MuiInputBase-input': { color: value ? theme.palette.success.main : theme.palette.error.main, fontWeight: 'bold' },
});

const generateProductInfo = (
  product: Product,
  navigate: NavigateFunction,
  onUpdateProduct: (newFields: { default_exchange?: string; pricing_tenors?: number }) => void,
  user?: User
) => [
  {
    label: 'Short name',
    value: product.short_name,
  },

  {
    label: 'Product group',
    value: PRODUCT_GROUP_NAME[product.product_group],
  },

  {
    label: 'Currency unit',
    value: product.currency_unit,
  },
  {
    label: 'Calendar type',
    value: product.calendar_type,
  },
  {
    label: 'Product type',
    value: product.product_type,
  },
  {
    label: 'Pricing tenors',
    value: product.pricing_tenors,
    editable: user?.is_flux_admin,
    inputType: 'input',
    onChange: (value: number) => onUpdateProduct({ pricing_tenors: value }),
    error: product.pricing_tenors === 0,
  },
  {
    label: 'Tenor frequency',
    value: product.tenor_frequency,
  },
  {
    label: 'MDX Name',
    value: product.mdx_name,
  },
  {
    label: 'Tick Size',
    value: product.tick_size,
  },
  {
    label: 'Underlying symbol',
    value: product.underlying_symbol,
    onClick: () => navigate(`/admin/products/${product.underlying_symbol}`),
    sx: { cursor: 'pointer' },
  },
  {
    label: 'Tradable',
    value: product.tradable,
    sx: getBooleanStyles(product.tradable),
  },
  {
    label: 'Enabled',
    value: product.enabled,
    sx: getBooleanStyles(product.enabled),
  },
  {
    label: 'Private',
    value: product.private,
    sx: getBooleanStyles(product.private),
  },
  {
    label: 'Multiplier',
    value: product.multiplier,
  },
  {
    label: 'Currency',
    value: product.currency,
  },
  {
    label: 'Quantity unit',
    value: product.quantity_unit,
  },
  {
    label: 'ID',
    value: product.id,
  },
  {
    label: 'Default exchange',
    value: product.default_exchange,
    editable: user?.is_flux_admin,
    inputType: 'select',
    options: ['cme', 'ice', 'unspecified', 'onyx'],
    onChange: (value: string) => onUpdateProduct({ default_exchange: value }),
  },
];

const getInputFields = (
  product: Product,
  navigate: NavigateFunction,
  onUpdateProduct: (newFields: { default_exchange?: string; pricing_tenors?: number }) => void,
  user?: User
) => {
  return generateProductInfo(product, navigate, onUpdateProduct, user).map((data, idx) => (
    <>
      {data.editable && data.inputType === 'input' && (
        <TextField
          label={data.label}
          defaultValue={`${data.value}`}
          value={`${data.value}`}
          key={`user_info_${idx}`}
          sx={data.sx}
          onChange={data.onChange}
          error={data.error}
        />
      )}
      {data.editable && data.inputType === 'select' && (
        <TextInputSelectBox
          label={data.label}
          value={`${data.value}`}
          key={`user_info_${idx}`}
          sx={data.sx}
          onChange={data.onChange}
          options={data.options || []}
        />
      )}
      {!data.editable && (
        <TextInputReadOnlyBox label={data.label} value={`${data.value}`} key={`user_info_${idx}`} sx={data.sx} onClick={data.onClick} />
      )}
    </>
  ));
};

export const ProductInfo: React.FC<Props> = ({ product, onProductUpdated }) => {
  const navigate = useNavigate();
  const { apiClient } = useAdminApi();
  const { isMobile } = useScreenSize();
  const user = useUserContext();

  const onUpdateProduct = useCallback(
    ({ default_exchange, pricing_tenors }: { default_exchange?: string; pricing_tenors?: number }) => {
      if (apiClient && product && (pricing_tenors || default_exchange)) {
        try {
          apiClient.updateProduct(product.symbol, { default_exchange, pricing_tenors }).then(data => {
            onProductUpdated(data);
          });
        } catch (e) {
          console.error(e);
        }
      } else {
        if (!default_exchange) onProductUpdated({ ...product, pricing_tenors: 0 });
      }
    },
    [apiClient, product]
  );

  const inputs = getInputFields(product, navigate, onUpdateProduct, user);

  const numberOfColumns = isMobile ? 1 : 5;

  const chunks = chunkArray(inputs, numberOfColumns);

  return (
    <Paper
      style={{
        background: 'transparent',
      }}
    >
      <MultilineGroup>
        {chunks.map((chunk, idx) => {
          if (chunk.every(item => item === null)) return null;
          return <InlineGroup key={`group_${idx}`}>{chunk as ReactNode[]}</InlineGroup>;
        })}
      </MultilineGroup>
    </Paper>
  );
};
