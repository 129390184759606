import { Box, Button, ButtonProps, Typography, styled } from '@mui/material';
import { OtcExchange, OtcOrderType, OtcSide } from '@protos/v2/otcQuote';
import { priceFormatterService } from '@services/PriceFormatterService';
import { streamV2RFQ } from '@services/context';
import { useCallback } from 'react';
import { useTradingContext } from './TradingContext';

interface TradingButtonsProps {
  rfqSubscriptionSymbol: string;
  rfqSubscriptionSize: number;
  isDisabled: boolean;
  tradingAccount: string;
}

type TradingButtonProps = { isBuy?: boolean } & ButtonProps;
const TradingButton = styled(Button, { shouldForwardProp: propName => propName !== 'isBuy' })<TradingButtonProps>(({ theme, isBuy }) => ({
  borderRadius: 5,
  width: '100%',
  backgroundColor: isBuy ? theme.palette.background.buy : theme.palette.background.sell,
  display: 'flex',
  flexDirection: 'column',
  padding: 5,
  height: 45,
  marginBottom: 5,
  transition: 'background-color 0.3s ease, transform 0.3s ease',

  '&:hover': {
    backgroundColor: isBuy ? '#6096eb !important' : '#c96565 !important',
    transform: 'translateY(5%)',
    cursor: 'pointer',
  },
}));

export const TradingButtons: React.FC<TradingButtonsProps> = ({ rfqSubscriptionSymbol, rfqSubscriptionSize, isDisabled, tradingAccount }) => {
  const { prices, isLoadingPrices } = useTradingContext();

  const onPlaceTrade = useCallback(
    (key: OtcSide) => {
      if (!rfqSubscriptionSymbol) return;

      const [front, back] = rfqSubscriptionSymbol.split('-');
      const isSpreadSymbol = rfqSubscriptionSymbol.split('-').length === 2;
      const tradeSellPrice = prices.sell?.price.toString();
      const tradeBuyPrice = prices.buy?.price.toString();
      if (!tradeSellPrice || !tradeBuyPrice) return;

      streamV2RFQ.placeOrderQuote({
        symbol: isSpreadSymbol ? { front, back } : rfqSubscriptionSymbol,
        amount: rfqSubscriptionSize.toString(),
        side: key,
        price: key === 'buy' ? tradeBuyPrice : tradeSellPrice,
        exchange: OtcExchange.ice,
        account_id: tradingAccount,
        order_type: OtcOrderType.fill_or_kill,
      });
    },
    [prices, rfqSubscriptionSymbol, tradingAccount]
  );

  return rfqSubscriptionSymbol ? (
    <Box display="flex" gap={1} width="100%" justifyContent="center">
      <TradingButton
        key="sell"
        variant="contained"
        onClick={() => onPlaceTrade(OtcSide.sell)}
        tabIndex={2}
        disabled={isLoadingPrices || isDisabled || !prices.sell}
        data-testid="place-order-button"
      >
        <Typography variant="body2" style={{ fontSize: 9, lineHeight: 0, marginBottom: 3 }} tabIndex={-1}>
          Sell
        </Typography>

        <Typography variant="body2" style={{ fontSize: 11, fontWeight: 800 }} tabIndex={-1}>
          {prices.sell ? priceFormatterService.formatProductPrice(prices.sell.price) : 'N/A'}
        </Typography>
        <Typography variant="body2" style={{ fontSize: 9, lineHeight: 0 }} tabIndex={-1}>
          {prices.sell ? `${prices.sell.amount} Lots` : ''}
        </Typography>
      </TradingButton>
      <Box
        style={{
          fontSize: 9,
          position: 'absolute',
          color: '#585858',
          backgroundColor: '#FFFFFF',
          borderRadius: 3,
          alignSelf: 'end',
          zIndex: 1,
          paddingLeft: 1,
          paddingRight: 1,
          display: 'flex',
          alignItems: 'center',
          maxHeight: 13,
        }}
      >
        {prices.buy && prices.sell ? `${Math.abs(Number(prices.buy?.price) - Number(prices.sell?.price)).toFixed(3)}` : 'N/A'}
      </Box>
      <TradingButton
        key="buy"
        variant="contained"
        isBuy
        onClick={() => onPlaceTrade(OtcSide.buy)}
        tabIndex={2}
        disabled={isLoadingPrices || isDisabled || !prices.buy}
        data-testid="place-order-button"
      >
        <Typography variant="body2" style={{ fontSize: 9, lineHeight: 0, marginBottom: 3 }} tabIndex={-1}>
          Buy
        </Typography>
        <Typography variant="body2" style={{ fontSize: 11, fontWeight: 800 }} tabIndex={-1}>
          {prices.buy ? priceFormatterService.formatProductPrice(prices.buy.price) : 'N/A'}
        </Typography>
        <Typography variant="body2" style={{ fontSize: 9, lineHeight: 0 }} tabIndex={-1}>
          {prices.buy ? `${prices.buy.amount} Lots` : ''}
        </Typography>
      </TradingButton>
    </Box>
  ) : null;
};
