import { ProductTenor } from '@protos/product';
import { OtcExchange } from '@protos/v2/otcQuote';
import { ReactNode, createContext, useCallback, useContext, useMemo, useState } from 'react';

type TradingTenorState = {
  widgetTenorMap: Record<
    string,
    { isSpread: boolean; exchange: OtcExchange; tenorsUsed: (ProductTenor | { left: ProductTenor; right: ProductTenor })[] }
  >;
  setWidgetTenorMap: React.Dispatch<
    React.SetStateAction<
      Record<
        string,
        {
          isSpread: boolean;
          exchange: OtcExchange;
          tenorsUsed: (ProductTenor | { left: ProductTenor; right: ProductTenor })[];
        }
      >
    >
  >;
  getAllUsedTenors: (id: string, isSpread: boolean, currentExchange: OtcExchange) => (ProductTenor | { left: ProductTenor; right: ProductTenor })[];
};

type Props = {
  children: ReactNode;
};

const TradingTenorContext = createContext<TradingTenorState | undefined>(undefined);
TradingTenorContext.displayName = 'TradingTenorContext';

export default function TradingTenorProvider({ ...otherProps }: Props) {
  const [widgetTenorMap, setWidgetTenorMap] = useState<
    Record<string, { isSpread: boolean; exchange: OtcExchange; tenorsUsed: (ProductTenor | { left: ProductTenor; right: ProductTenor })[] }>
  >({});

  const getAllUsedTenors = useCallback(
    (id: string, isSpread: boolean, currentExchange: OtcExchange) => {
      const allUsedTenors: Set<ProductTenor | { left: ProductTenor; right: ProductTenor }> = new Set();
      Object.entries(widgetTenorMap).forEach(([widgetId, { isSpread: spread, tenorsUsed, exchange }]) => {
        if (widgetId === id) return;

        if (spread === isSpread && exchange === currentExchange) {
          tenorsUsed.forEach(tenor => allUsedTenors.add(tenor));
        }
      });

      return Array.from(allUsedTenors);
    },
    [widgetTenorMap]
  );

  const value: TradingTenorState = useMemo(
    () => ({
      widgetTenorMap,
      setWidgetTenorMap,
      getAllUsedTenors,
    }),
    [widgetTenorMap, getAllUsedTenors]
  );

  return <TradingTenorContext.Provider value={value} {...otherProps} />;
}

export function useTradingTenorContext(): TradingTenorState {
  const context = useContext(TradingTenorContext);

  if (!context) {
    throw new Error('useTradingTenorContext must be used within a TradingTenorProvider');
  }

  return context;
}
