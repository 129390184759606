import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { memo, useEffect, useRef } from 'react';
import { Row } from '../types';

type FlashingCellProps = {
  value: ICellRendererParams<Row, number>['value'];
  valueFormatted: ICellRendererParams<Row, number>['valueFormatted'];
  eGridCell?: HTMLElement;
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: 11,
})) as typeof Typography;

const highValueClassName = 'high-value';
const lowValueClassName = 'low-value';

export function getCellClassName(prevValue: number | null | undefined, newValue: number | null | undefined): string | undefined {
  const prevValNum = Number(prevValue) || 0;
  const newValNum = Number(newValue) || 0;

  if (prevValNum < newValNum) {
    return highValueClassName;
  }

  if (prevValNum > newValNum) {
    return lowValueClassName;
  }

  return;
}

export function FlashingCell({ value, eGridCell }: FlashingCellProps) {
  const lastValueRef = useRef<number | null | undefined>(null);
  const firstRender = useRef<boolean>(true);

  useEffect(() => {
    if (firstRender.current) {
      lastValueRef.current = value;
      firstRender.current = false;
      return;
    }

    if (!value || value === 0) {
      eGridCell?.classList.remove(highValueClassName, lowValueClassName, 'teal-cell');
      return;
    }

    const animationClassName = getCellClassName(lastValueRef.current, value);
    if (!animationClassName || !eGridCell) return;

    eGridCell.classList.add(animationClassName);

    const resetAnimation = () => {
      eGridCell.classList.remove(animationClassName);
      eGridCell.classList.add('teal-cell');
    };
    const timeout = setTimeout(resetAnimation, 2000);

    lastValueRef.current = value;

    return () => clearTimeout(timeout);
  }, [eGridCell, value]);

  if (!value) return null;

  return <StyledTypography>{value}</StyledTypography>;
}
const MemoFlashingCell = memo(FlashingCell);

export default function FlashingCellRenderer({ value, valueFormatted, eGridCell }: ICellRendererParams<Row, number>) {
  return <MemoFlashingCell value={value == 0 ? null : value} valueFormatted={valueFormatted} eGridCell={eGridCell} />;
}
