import { Widget } from '@features/dashboard/types';
import { Dashboard } from '@protos/dashboard';
import ApiClient from '@services/ApiClient';
import { ChannelTypeV1, StreamEvent, Subscription } from '@services/Stream';
import { toastifyService } from '@services/ToastifyService';
import { config, logger, stream } from '@services/context';
import { useCallback, useState } from 'react';

const cli = new ApiClient(config.apiUrl, '');

const generateNewDashboardName = (allDashboards: Dashboard[]) => {
  const keyword = 'dashboard';
  const data = allDashboards || [];
  if (data.length === 0) return 'Default';
  const index = data.reduce((maxIndex, d) => {
    const bits = d.name.split('-');
    if (bits.length === 2 && bits[0] === keyword) {
      const index = +bits[1];
      if (index > maxIndex) return index;
    }
    return maxIndex;
  }, 0);
  return `${keyword}-${index + 1}`;
};

export const useStreamDashboardService = () => {
  const [allDashboards, setAllDashboards] = useState<Dashboard[]>([]);
  const [currentDashboard, setCurrentDashboard] = useState<Dashboard | undefined>(allDashboards[0]);
  const [isDashboardLoading, setIsDashboardLoading] = useState(true);

  const updateDashboard = useCallback(
    async (updatedDashboard: Dashboard) => {
      const transformedUpdatedDashboard = { ...updatedDashboard, websocket_uid: stream.uid || '' };

      setAllDashboards(prev => prev.map(dashboard => (dashboard.id === transformedUpdatedDashboard.id ? transformedUpdatedDashboard : dashboard)));
      if (currentDashboard?.id === transformedUpdatedDashboard.id) {
        setCurrentDashboard(transformedUpdatedDashboard);
      }

      await stream.modifyDashboard(transformedUpdatedDashboard.id, transformedUpdatedDashboard);
      return transformedUpdatedDashboard;
    },
    [currentDashboard, stream]
  );

  const addDashboard = useCallback(
    async (overrideDashboardName?: string) => {
      const newDashboard = await cli.createDashboard({
        name: overrideDashboardName || generateNewDashboardName(allDashboards),
        json: {},
        websocket_uid: stream.uid,
      });

      setAllDashboards(prev => [...prev, newDashboard]);
      setCurrentDashboardById(newDashboard.id.toString());

      return newDashboard;
    },
    [allDashboards, stream]
  );

  const deleteDashboard = useCallback(
    async (id: string) => {
      const transformedDashboards = allDashboards.filter(dashboard => dashboard.id.toString() !== id);

      setAllDashboards(transformedDashboards);
      if (currentDashboard?.id.toString() === id) {
        setCurrentDashboard(transformedDashboards[0]);
      }

      await cli.deleteDashboard(+id);
    },
    [allDashboards, currentDashboard]
  );

  const setCurrentDashboardById = useCallback(
    (id: string) => {
      const dashboard = allDashboards.find(d => d.id.toString() === id);
      if (dashboard) setCurrentDashboard(dashboard);
    },
    [allDashboards]
  );

  const clearCurrentDashboard = useCallback(() => {
    setCurrentDashboard(undefined);
  }, []);

  const editWidgetPayloadById = useCallback(
    async (id: string, newPayload: Record<string, any>) => {
      try {
        if (!currentDashboard) return;

        const newJsonWidgets = currentDashboard.json?.widgets?.map((widget: Widget) => {
          if (widget.id === id) {
            widget.payload = { ...widget.payload, ...newPayload };
          }
          return widget;
        });
        const transformedDashboardJson = { ...currentDashboard.json, widgets: newJsonWidgets };

        const newDashboard = { ...currentDashboard, json: transformedDashboardJson };
        await updateDashboard(newDashboard);
      } catch (error: any) {
        toastifyService.showErrorMessage('Something went wrong, please refresh and try again');
        logger.error('Unexpected error', error);
      }
    },
    [currentDashboard, updateDashboard]
  );

  const deleteWidgetById = useCallback(
    async (id: string) => {
      if (!currentDashboard) return;

      const newJsonWidgets = currentDashboard.json?.widgets.filter((widget: Widget) => widget.id !== id);
      const transformedDashboardJson = { ...currentDashboard.json, widgets: newJsonWidgets };

      const newDashboard = { ...currentDashboard, json: transformedDashboardJson };
      await updateDashboard(newDashboard);
    },
    [currentDashboard, updateDashboard]
  );

  const addNewWidgets = useCallback(
    async (widgets: Widget[]) => {
      if (!currentDashboard) return;

      const newJsonWidgets = [...(currentDashboard.json?.widgets || []), ...widgets];
      const transformedDashboardJson = { ...currentDashboard.json, widgets: newJsonWidgets };

      const newDashboard = { ...currentDashboard, json: transformedDashboardJson };
      await updateDashboard(newDashboard);
    },
    [currentDashboard, updateDashboard]
  );

  const editDashboardLayouts = useCallback(
    async (layouts: any) => {
      if (!currentDashboard) return;

      const newJson = { ...currentDashboard.json, layouts: { ...currentDashboard.json?.layouts, ...layouts } };
      const newDashboard = { ...currentDashboard, json: newJson };
      await updateDashboard(newDashboard);
    },
    [currentDashboard, updateDashboard]
  );

  const subscribeToDashboards = useCallback(() => {
    const handleDashboardEvent = async (event: StreamEvent) => {
      cli.setToken(stream.authToken);
      const eventData = event.asDashboards();
      if (!eventData) return;

      const isLocalMessageEvent = !eventData.isSnapshot && eventData.dashboards.length && eventData.dashboards[0].websocket_uid === stream.uid;
      if (isLocalMessageEvent) {
        if (isDashboardLoading) setIsDashboardLoading(false);
        return;
      }

      eventData.dashboards.forEach(dashboard => {
        if (eventData.isDelete) {
          logger.warn('Received delete message for dashboard', dashboard.id, ' - remove it from list');
          const transformedDashboards = allDashboards.filter(d => d.id !== dashboard.id);

          setAllDashboards(transformedDashboards);
          if (currentDashboard?.id === dashboard.id) {
            setCurrentDashboard(transformedDashboards[0]);
          }
        } else {
          logger.info('Received', eventData.messageType, 'message for dashboard', dashboard.id, ' - add it to the list');

          const isAddDashboardEvent = !allDashboards.some(d => d.id === dashboard.id);
          if (isAddDashboardEvent) {
            setAllDashboards(prev => [...prev, dashboard]);
          } else {
            const updatedDashboards = allDashboards.map(d => (d.id === dashboard.id ? dashboard : d));
            const updatedCurrentDashboard = updatedDashboards.find(d => d.id === currentDashboard?.id);

            setAllDashboards(updatedDashboards);
            setCurrentDashboard(updatedCurrentDashboard);
          }
        }
      });

      if (isDashboardLoading) setIsDashboardLoading(false);
    };

    stream.onConnect(() => {
      stream.subscribe(Subscription.dashboards());
    }, ChannelTypeV1.Dashboards);

    const unsubscribe = stream.onEvent(ChannelTypeV1.Dashboards, handleDashboardEvent);

    return { unsubscribe };
  }, [allDashboards, stream, isDashboardLoading, currentDashboard]);

  const createDefaultDashboard = useCallback(async () => {
    try {
      const dashboard = await cli.createDashboard({ name: 'Default', json: {} });
      setAllDashboards(prev => [...prev, dashboard]);
      setCurrentDashboardById(dashboard.id.toString());
    } catch (error: any) {
      logger.warn(error);
    }
    if (isDashboardLoading) setIsDashboardLoading(false);
  }, [isDashboardLoading]);

  return {
    allDashboards,
    currentDashboard,
    addDashboard,
    updateDashboard,
    deleteDashboard,
    setCurrentDashboardById,
    editWidgetPayloadById,
    deleteWidgetById,
    addNewWidgets,
    clearCurrentDashboard,
    isDashboardLoading,
    editDashboardLayouts,
    createDefaultDashboard,
    subscribeToDashboards,
  };
};
