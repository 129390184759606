import { Autocomplete, Box, Popper, Stack, styled, TextField, Typography } from '@mui/material';
import { Product } from '@protos/product';
import { useUserContext } from '@shared/contexts/UserContextProvider';
import { useUserProductsAndTenorsContext } from '@shared/contexts/UserProductsAndTenorsProvider';
import { useCallback, useEffect, useState } from 'react';
import NumberInput from '../components/NumberInput';

const SmallAutocomplete = styled(Autocomplete)({
  width: '125px',
  borderRadius: 0,
  '& .MuiAutocomplete-inputRoot': {
    padding: 0,
    fontSize: 11,
    height: 25,
    borderRadius: 0,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 20,
    padding: 0,
  },
});

const StyledPopper = styled(Popper)({
  '& .MuiAutocomplete-listbox': {
    fontSize: 11,
  },
});

interface MixedGridSettings {
  symbol: string;
  validity: number;
  size: number;
}

interface MixedGridSettingsProps {
  settings: MixedGridSettings;
  onSettingsChange: (settings: MixedGridSettings) => void;
}

export const MixedGridSettings = ({ settings, onSettingsChange }: MixedGridSettingsProps) => {
  const user = useUserContext();
  const { userProducts } = useUserProductsAndTenorsContext();
  const [selectedValue, setSelectedValue] = useState<Product>();

  const allowedProducts = user?.trade_limits.filter(limit => limit.exchange.includes('onyx')).map(limit => limit.symbol) || [];

  const allTradableProducts = userProducts.filter(
    product => allowedProducts.includes(product.symbol) && ['swap', 'composite'].includes(product.product_type)
  );

  const onProductChange = useCallback(
    (newProductValue: string) => {
      const product = allTradableProducts.find(p => p.symbol === newProductValue);
      if (product) {
        setSelectedValue(product);
        onSettingsChange({ ...settings, symbol: product.symbol });
      }
    },
    [allTradableProducts, onSettingsChange, settings]
  );

  useEffect(() => {
    if (!allTradableProducts.length) return;

    if (!selectedValue) {
      setSelectedValue(allTradableProducts.find(p => p.symbol === settings.symbol) || allTradableProducts[0]);
    } else if (selectedValue.symbol !== settings.symbol) {
      onSettingsChange({ ...settings, symbol: selectedValue.symbol });
    }
  }, [selectedValue, settings, allTradableProducts]);

  if (!selectedValue) return null;

  return (
    <Stack display="flex" flex={1} gap={2}>
      <Typography variant="h6" style={{ marginRight: 'auto' }}>
        {selectedValue.full_name}
      </Typography>
      <Box display="flex" flex={1} flexDirection="column" justifyContent="start" flexWrap="wrap">
        <NumberInput
          value={settings.validity.toString()}
          onChange={value => value && onSettingsChange({ ...settings, validity: value })}
          label="Validity (s)"
          sx={{
            '& .MuiInputBase-input': {
              fontSize: '11px',
              '--Input-minHeight': '0',
            },
            '& .MuiInputBase-root': {
              borderRadius: '0px',
              height: 25,
            },
          }}
        />
        <SmallAutocomplete
          size="small"
          disableClearable
          options={allTradableProducts}
          getOptionLabel={(option: unknown) => (option as Product).symbol.toUpperCase()}
          value={selectedValue}
          onChange={(_, newValue) => newValue && onProductChange((newValue as Product).symbol)}
          autoHighlight
          PopperComponent={props => <StyledPopper {...props} />}
          renderInput={params => <TextField {...params} variant="outlined" tabIndex={-1} />}
          isOptionEqualToValue={(option, value) => (option as Product).symbol === (value as Product).symbol}
          sx={{
            '& .MuiAutocomplete-input': { fontSize: 11, paddingLeft: 1.5 },
          }}
        />
      </Box>
    </Stack>
  );
};
