import { DEFAULT_ALLOWED_PRICING_TENORS } from '@features/dashboard/widgets/shared/hooks/usePricesDefaultFilters';
import { RollingRowSettings } from '@features/dashboard/widgets/shared/types';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Collapse,
  Divider,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  styled,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { IconComponent } from '@shared/components/IconComponent';
import { NumberInput } from '@shared/components/NumberInput';
import { useUserProductsAndTenorsContext } from '@shared/contexts/UserProductsAndTenorsProvider';
import { ProductTenor } from '@shared/protos/product';
import { useCallback, useMemo, useState } from 'react';
import { usePricingSheetSettings } from '../PricingSheetContext';
import { getRollingNumberInputMinMaxForSettings, ROLLING_LABEL_MAP } from '../utils';

const icon = <IconComponent name="ri-checkbox-blank-line" />;
const checkedIcon = <IconComponent name="ri-checkbox-line" />;
const ROLLING_TENORS = ['daily', 'weekly', 'monthly', 'quarterly', 'yearly'];

const StyledList = styled(List)({
  boxSizing: 'content-box',
  padding: 0.5,
  overflow: 'auto',
  maxHeight: 600,
  marginTop: '10px !important',
  marginLeft: '0px !important',
  marginRight: '-4px !important',
  border: '1px solid #505050',
  boxShadow: '0px 2px 10px 2px rgba(0, 0, 0, 0.1)',
});

export const PricingSheetTenorsSettingsForm = () => {
  const { addRow, toggleRow, filteredSelectedTenors, rollingRowSettings, updateRollingSettings, isOverrideRolling, toggleIsOverrideRolling } =
    usePricingSheetSettings();
  const { tenors } = useUserProductsAndTenorsContext();

  const allTenorGroups = useMemo(() => {
    const groups: { [key: string]: ProductTenor[] } = {};
    tenors.forEach(tenor => {
      if (!groups[tenor.frequency]) {
        groups[tenor.frequency] = [];
      }
      groups[tenor.frequency].push(tenor);
    });
    return groups;
  }, [tenors]);

  const [expandedGroups, setExpandedGroups] = useState<string[]>([]);
  const [searchInput, setSearchInput] = useState('');

  const filteredTenorGroups = useMemo(() => {
    if (!searchInput) return allTenorGroups;

    const filteredGroups: { [key: string]: ProductTenor[] } = {};

    Object.keys(allTenorGroups).forEach(groupName => {
      const allMatchingTenorsInGroup = allTenorGroups[groupName].filter(
        tenor => tenor.display.toLowerCase().includes(searchInput.toLowerCase()) || tenor.code.toLowerCase().includes(searchInput.toLowerCase())
      );

      if (allMatchingTenorsInGroup.length) {
        filteredGroups[groupName] = allMatchingTenorsInGroup;
      }
    });
    return filteredGroups;
  }, [searchInput, allTenorGroups]);

  const onChangeValues = useCallback(
    (selectedValues: ProductTenor[] | null) => {
      const deltaValues = filteredSelectedTenors.filter(({ code }) => !selectedValues?.find(elem => elem.code === code));
      if (deltaValues.length) {
        deltaValues.forEach(removedValue => {
          toggleRow(removedValue.code);
        });
      }

      if (selectedValues?.length && addRow) {
        selectedValues.forEach(selectedValue => {
          addRow(selectedValue.code);
        });
      }
    },
    [toggleRow, addRow, filteredSelectedTenors]
  );

  const onRollingTenorValueChange = useCallback(
    (frequency: string, newValue: number | undefined) => {
      if (newValue !== undefined && newValue >= 0 && rollingRowSettings) {
        updateRollingSettings({ ...rollingRowSettings, [frequency]: newValue });
      }
    },
    [updateRollingSettings, rollingRowSettings]
  );

  const onRemoveTenor = useCallback(
    (code: string) => {
      const newSelectedValues = filteredSelectedTenors.filter(tenor => tenor.code !== code);
      onChangeValues(newSelectedValues.map(({ code }) => tenors.find(tenor => tenor.code === code)!));
    },
    [filteredSelectedTenors, onChangeValues, tenors]
  );

  const onCheckboxChange = useCallback(
    (tenor: ProductTenor) => {
      const newSelectedValues = filteredSelectedTenors.find(elem => elem.code === tenor.code)
        ? filteredSelectedTenors.filter(({ code }) => code !== tenor.code)
        : [...filteredSelectedTenors, tenor];
      onChangeValues(newSelectedValues.map(({ code }) => tenors.find(tenor => tenor.code === code)!));
    },
    [filteredSelectedTenors, onChangeValues, tenors]
  );

  const onToggleGroupClick = useCallback(
    (groupName: string) => {
      if (expandedGroups.includes(groupName)) {
        const idx = expandedGroups.findIndex(elem => elem === groupName);
        const newList = [...expandedGroups];
        newList.splice(idx, 1);
        setExpandedGroups(newList);
      } else {
        setExpandedGroups([...expandedGroups, groupName]);
      }
    },
    [expandedGroups]
  );

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" gap={2} alignItems="center" flexWrap="wrap" useFlexGap>
        {ROLLING_TENORS.map(tenor => (
          <NumberInput
            key={tenor}
            disabled={isOverrideRolling}
            label={ROLLING_LABEL_MAP[tenor]}
            value={(rollingRowSettings ?? DEFAULT_ALLOWED_PRICING_TENORS)[tenor]}
            onChange={(e, value) => onRollingTenorValueChange(tenor, value)}
            min={getRollingNumberInputMinMaxForSettings(tenor as keyof RollingRowSettings).min}
            max={getRollingNumberInputMinMaxForSettings(tenor as keyof RollingRowSettings).max}
          />
        ))}
      </Stack>
      <Stack direction="row" alignItems="center" style={{ marginTop: 50 }}>
        <Switch checked={isOverrideRolling} size="small" onChange={() => toggleIsOverrideRolling()} />
        <Typography fontSize={11}>Enable Manual tenor selection</Typography>
      </Stack>

      {isOverrideRolling && (
        <Stack direction="column" spacing={2}>
          <Box display="flex" flex={1}>
            <Box display="flex" flexWrap="wrap" flex={1} gap={1}>
              {filteredSelectedTenors.map(({ display, code }) => {
                return (
                  <Chip key={code} label={display.toLocaleUpperCase()} onDelete={() => onRemoveTenor(code)} size="small" sx={{ fontSize: 10 }} />
                );
              })}
            </Box>
            <Button variant="outlined" size="small" onClick={() => onChangeValues([])} style={{ fontSize: 9, alignSelf: 'end' }}>
              Clear All
            </Button>
          </Box>

          <Divider>
            <Typography variant="caption" fontSize={9}>
              Add / Remove Tenors Below
            </Typography>
          </Divider>

          <TextField
            placeholder="Search tenors"
            size="small"
            value={searchInput}
            onChange={e => setSearchInput(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon sx={{ fontSize: 15 }} />
                </InputAdornment>
              ),
            }}
            fullWidth
            sx={{
              '& .MuiInputBase-input': {
                fontSize: 11,
              },
            }}
          />

          {Object.keys(filteredTenorGroups).length ? (
            <StyledList>
              {Object.keys(filteredTenorGroups).map((groupName, index) => (
                <Stack key={groupName}>
                  <ListItemButton
                    onClick={() => onToggleGroupClick(groupName)}
                    sx={{ backgroundColor: '#585858', padding: '5px', paddingTop: 0, paddingBottom: 0 }}
                  >
                    <ListItemText
                      primary={groupName}
                      primaryTypographyProps={{
                        style: {
                          fontSize: 11,
                        },
                      }}
                    />
                    {expandedGroups.includes(groupName) ? <ExpandLess sx={{ fontSize: 17 }} /> : <ExpandMore sx={{ fontSize: 17 }} />}
                  </ListItemButton>
                  <Collapse in={expandedGroups.includes(groupName)} timeout="auto" unmountOnExit>
                    <List
                      component="div"
                      disablePadding
                      sx={{ borderLeft: '1px solid #505050', borderRight: '1px solid #505050', marginLeft: 0.1, marginRight: 0.1 }}
                    >
                      {filteredTenorGroups[groupName].map((tenor, index) => (
                        <>
                          <ListItem key={tenor.code} disableGutters>
                            <ListItemIcon>
                              <Checkbox
                                size="small"
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={filteredSelectedTenors.find(elem => elem.code === tenor.code) !== undefined}
                                onChange={() => onCheckboxChange(tenor)}
                              />
                            </ListItemIcon>
                            <ListItemText primary={<Typography fontSize={11}>{tenor.display}</Typography>} />
                            <Typography variant="caption" fontSize={11} sx={{ textAlign: 'end', marginRight: 1 }}>
                              {tenor.display.toLocaleUpperCase()}
                            </Typography>
                          </ListItem>
                          <Divider />
                        </>
                      ))}
                    </List>
                  </Collapse>
                  {index !== Object.keys(filteredTenorGroups).length - 1 && <Divider />}
                </Stack>
              ))}
            </StyledList>
          ) : (
            <Typography textAlign="center" variant="body">
              No tenors found
            </Typography>
          )}
        </Stack>
      )}
    </Stack>
  );
};
