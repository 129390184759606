import { Grid } from '@features/dashboard/widgets/grid';
import { ColorPicker } from '@pages/ocl-trading/components/ColorPicker';
import { TENOR_COL_ID } from '@protos/grids';
import { useUserContext } from '@shared/contexts/UserContextProvider';
import { GetRowIdParams } from 'ag-grid-enterprise';
import { useEffect, useMemo } from 'react';
import { usePricingSheetSettings } from '../PricingSheetContext';
import { usePricesGridSheet } from '../hooks/usePricesGridSheet';
import { usePricingSheetGrid } from '../hooks/usePricingSheetGrid';
import { PricesSheetRow } from '../hooks/usePricingSheetGridState';
import { PricingSheetCellEditor } from './PricingSheetCellEditor';

function getRowId({ data: { tenorId } }: GetRowIdParams<PricesSheetRow>): string {
  return tenorId;
}

export default function PricingSheetGrid({ validity }: { validity: number }) {
  const user = useUserContext();
  const userPermissionProducts = user?.trade_limits.map(limit => limit.symbol);
  const { columnDefs, columns, rows, filteredSelectedTenors, onColumnMoved, gridRef, productMap, tenorMap } = usePricingSheetSettings();

  const { getRowStyle, isExternalFilterPresent, doesExternalFilterPass, getAllRows, selectedRowIds } = usePricingSheetGrid(
    rows ?? [],
    filteredSelectedTenors
  );

  const {
    showColorPicker,
    activeColorKey,
    handleColorChange,
    handleColorComplete,
    tempColor,
    setTempColor,
    setShowColorPicker,
    getStandaloneContextMenuItems,
    standaloneProps,
  } = usePricesGridSheet(userPermissionProducts || []);

  const priceColumns = useMemo(() => {
    return columns?.reduce((acc, col: any) => {
      if (col.colId !== TENOR_COL_ID) {
        acc.push({
          ...col,
          editable: userPermissionProducts?.includes(col.colId),
          cellStyle: (params: any) => {
            const cellKey = `${params.data.tenorId}-${col.colId}`;
            const customStyle = standaloneProps?.cellStyles?.[cellKey];

            return {
              ...customStyle,
            };
          },
          cellEditor: PricingSheetCellEditor,
          cellEditorParams: { validity },
        });
      } else {
        acc.push(col);
      }
      return acc;
    }, [] as any);
  }, [columns, user, standaloneProps, productMap, tenorMap]);

  useEffect(() => {
    // After filters have been changed via API, we must ensure that the grid is refreshed;
    // If ´api.onFilterChanged()´ is not called, the grid will not be refreshed.
    if (gridRef?.current) {
      gridRef.current.getGridApi()?.onFilterChanged();
    }
  }, [selectedRowIds]);

  return (
    <>
      <Grid<PricesSheetRow>
        ref={gridRef}
        defaultColDef={columnDefs}
        columnDefs={priceColumns}
        rowData={getAllRows()}
        getRowId={getRowId}
        getContextMenuItems={getStandaloneContextMenuItems}
        isExternalFilterPresent={isExternalFilterPresent}
        doesExternalFilterPass={doesExternalFilterPass}
        onColumnMoved={onColumnMoved}
        suppressRowVirtualisation
        suppressColumnVirtualisation
        getRowStyle={getRowStyle}
        tooltipShowDelay={1000}
        tooltipInteraction={true}
        autoSizeStrategy={undefined}
        domLayout="autoHeight"
      />
      {showColorPicker && activeColorKey && (
        <ColorPicker
          onClose={() => {
            setShowColorPicker(false);
            setTempColor(undefined);
          }}
          onColorComplete={handleColorComplete}
          color={
            tempColor ||
            standaloneProps?.cellStyles?.[activeColorKey.cellKey]?.[
              activeColorKey.type === 'background' ? 'backgroundColor' : activeColorKey.type === 'color' ? 'color' : 'borderColor'
            ] ||
            ''
          }
          onColorChange={handleColorChange}
        />
      )}
    </>
  );
}
