import { streamV2 } from '@services/context';
import { useAsync } from 'react-use';
import { useAppAuthentication } from './useAppAuthentication';

export const useStreamV2 = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAppAuthentication();

  return useAsync(async () => {
    if (streamV2.isStreamAuthenticated) return;
    if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      if (streamV2.getAuthToken != token) await streamV2.authenticate(token);
    } else {
      throw new Error('Not authenticated');
    }
  }, [isAuthenticated]);
};
