import { priceFormatterService } from '@services/PriceFormatterService';
import { Order } from '@shared/protos/trading';
import { ColDef } from 'ag-grid-enterprise';

export type TradingBlotterWidgetPayload = {
  searchTerm: string;
};

export const TRADING_BLOTTER_COLUMNS: ColDef<Order>[] = [
  {
    field: 'exchange_created_timestamp',
    headerName: 'Timestamp',
    valueFormatter: ({ value }) => (value ? new Date(value).toLocaleString() : ''),
    resizable: true,
    headerTooltip: 'Exchange Timestamp',
    sort: 'desc',
  },
  {
    field: 'user.full_name',
    headerName: 'User Full Name',
    resizable: true,
    headerTooltip: 'User Full Name',
  },
  {
    field: 'side',
    headerName: 'Side',
    headerTooltip: 'Side',
    valueFormatter: ({ value }) => (value ? value.toUpperCase() : ''),
    cellStyle: ({ value }) => ({ color: value.toLowerCase().includes('buy') ? '#4189E8' : '#CD4B4B' }),
  },
  {
    field: 'amount',
    headerName: 'Amount',
    headerTooltip: 'Amount',
    valueFormatter: ({ value }) => (value ? value.toLocaleString() : ''),
  },
  {
    field: 'symbol',
    headerName: 'Symbol',
    headerTooltip: 'Symbol',
    valueFormatter: ({ value }) => (value ? (typeof value === 'string' ? value.toUpperCase() : `${value.front} / ${value.back}`.toUpperCase()) : ''),
  },
  {
    field: 'price',
    headerName: 'Price',
    headerTooltip: 'Price',
    valueFormatter: ({ value }) => (value ? priceFormatterService.formatProductPrice(value) : ''),
  },
  {
    field: 'order_type',
    headerName: 'Order Type',
    headerTooltip: 'Order Type',
    valueFormatter: ({ value }) => (value ? value.toUpperCase() : ''),
  },
  {
    field: 'order_state',
    headerName: 'Order State',
    headerTooltip: 'Order State',
    valueFormatter: ({ value }) => (value ? value.toUpperCase() : ''),
  },
  {
    field: 'account_id',
    headerName: 'Account Id',
    headerTooltip: 'Account Id',
    resizable: true,
  },
  {
    field: 'trading_group',
    headerName: 'Venue',
    headerTooltip: 'Trading Group',
    valueFormatter: ({ value }) => (value ? value.toUpperCase() : ''),
  },
  {
    field: 'exchange',
    headerName: 'Exchange',
    headerTooltip: 'Exchange',
    valueFormatter: ({ value }) => (value ? value.toUpperCase() : ''),
  },
  {
    field: 'id',
    headerName: 'Order ID',
    headerTooltip: 'Order ID',
    resizable: true,
  },
];

export type TradingExecutionParams = {};
