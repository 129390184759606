import { Typography } from '@mui/material';
import { OrderState } from '@protos/trading';
import theme from '@shared/themes/darkTheme';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { memo, useEffect, useMemo, useState } from 'react';

const formatSecondsIntoReadableTime = (seconds: number) => {
  const days = Math.floor(seconds / 86400);
  seconds %= 86400;

  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;

  const minutes = Math.floor(seconds / 60);
  seconds %= 60;

  const parts: string[] = [];
  if (days > 0) parts.push(`${days}d`);
  if (hours > 0) parts.push(`${hours}h`);
  if (minutes > 0) parts.push(`${minutes}m`);
  if (seconds > 0 && parts.length === 0) parts.push(`${seconds}s`);

  return parts.join(' ');
};

export const DurationTimerCellRenderer = memo((params: ICellRendererParams) => {
  const { expiry_timestamp, order_state } = params.data;
  if (order_state === OrderState.EXPIRED || order_state === OrderState.CANCELLED) {
    return (
      <Typography sx={{ color: theme.palette.background.sell, fontSize: '11px', fontWeight: 600 }}>
        {order_state === OrderState.EXPIRED ? 'Expired' : 'Cancelled'}
      </Typography>
    );
  }
  const CURRENT_TIMESTAMP = useMemo(() => new Date().getTime(), []);
  const expiryTimestamp = useMemo(() => new Date(expiry_timestamp).getTime(), [expiry_timestamp]);

  const [timerDurationInSeconds, setTimerDurationInSeconds] = useState(Math.floor((expiryTimestamp - CURRENT_TIMESTAMP) / 1000));

  useEffect(() => {
    if (order_state === OrderState.EXPIRED || order_state === OrderState.CANCELLED) return;
    const interval = setInterval(() => {
      setTimerDurationInSeconds(prev => prev - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [expiryTimestamp]);

  if (!timerDurationInSeconds || timerDurationInSeconds <= 0)
    return <Typography sx={{ color: theme.palette.background.sell, fontSize: '11px', fontWeight: 600 }}>Expired</Typography>;

  return <Typography sx={{ fontSize: '11px', fontWeight: 600 }}>{formatSecondsIntoReadableTime(timerDurationInSeconds)}</Typography>;
});
