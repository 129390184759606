import { FormControl, TextField as MuiTextField, SxProps } from '@mui/material';
import theme from '@shared/themes/darkTheme';
import { StyledBox, StyledInputLabel } from './TextInput';

type Props = {
  label: string;
  value: string;
  onChange?: (e: any) => void;
  sx?: SxProps;
  defaultValue?: string;
  error?: boolean;
};

export const TextField: React.FC<Props> = ({ label, value, onChange, sx, defaultValue, error }) => {
  return (
    <StyledBox sx={{ padding: '8px 10px 13px', ...sx }}>
      <FormControl variant="standard" fullWidth sx={{ height: '100%' }}>
        <StyledInputLabel>{label}</StyledInputLabel>
        <MuiTextField
          defaultValue={defaultValue}
          value={value}
          onChange={value => {
            if (onChange) onChange(value.target.value);
          }}
          variant="standard"
          sx={{
            height: 'fit-content',
            paddingTop: '16px',
            input: {
              color: error ? theme.palette.error.main : 'default',
            },
            ...sx,
          }}
        />
      </FormControl>
    </StyledBox>
  );
};
