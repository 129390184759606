import { RollingRowSettings } from '@features/dashboard/widgets/shared/types';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Collapse,
  Divider,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { ProductTenor } from '@protos/product';
import { IconComponent } from '@shared/components/IconComponent';
import { NumberInput } from '@shared/components/NumberInput';
import { useUserProductsAndTenorsContext } from '@shared/contexts/UserProductsAndTenorsProvider';
import { useCallback, useMemo, useState } from 'react';

const icon = <IconComponent name="ri-checkbox-blank-line" />;
const checkedIcon = <IconComponent name="ri-checkbox-line" />;
const ROLLING_TENORS = ['daily', 'weekly', 'monthly', 'quarterly', 'yearly'];

interface MixedGridTenorsSettingsProps {
  selectedTenors: string[];
  rollingSettings: RollingRowSettings;
  isOverrideRolling: boolean;
  onTenorToggle: (id: string) => void;
  onRollingSettingsUpdate: (settings: RollingRowSettings) => void;
  onOverrideToggle: () => void;
}

export const MixedGridTenorsSettings = ({
  selectedTenors,
  rollingSettings,
  isOverrideRolling,
  onTenorToggle,
  onRollingSettingsUpdate,
  onOverrideToggle,
}: MixedGridTenorsSettingsProps) => {
  const { tenors } = useUserProductsAndTenorsContext();
  const [expandedGroups, setExpandedGroups] = useState<string[]>([]);
  const [searchInput, setSearchInput] = useState('');

  const allTenorGroups = useMemo(() => {
    const groups: { [key: string]: ProductTenor[] } = {};
    tenors.forEach(tenor => {
      if (!groups[tenor.frequency]) {
        groups[tenor.frequency] = [];
      }
      groups[tenor.frequency].push(tenor);
    });
    return groups;
  }, [tenors]);

  const filteredTenorGroups = useMemo(() => {
    if (!searchInput) return allTenorGroups;
    const filteredGroups: { [key: string]: ProductTenor[] } = {};

    Object.keys(allTenorGroups).forEach(groupName => {
      const matchingTenors = allTenorGroups[groupName].filter(
        tenor => tenor.display.toLowerCase().includes(searchInput.toLowerCase()) || tenor.code.toLowerCase().includes(searchInput.toLowerCase())
      );
      if (matchingTenors.length) {
        filteredGroups[groupName] = matchingTenors;
      }
    });
    return filteredGroups;
  }, [searchInput, allTenorGroups]);

  const onToggleGroupClick = useCallback((groupName: string) => {
    setExpandedGroups(prev => (prev.includes(groupName) ? prev.filter(g => g !== groupName) : [...prev, groupName]));
  }, []);

  const clearAllTenors = useCallback(() => {
    selectedTenors.forEach(tenorCode => onTenorToggle(tenorCode));
  }, [selectedTenors, onTenorToggle]);

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" gap={2} alignItems="center" flexWrap="wrap" useFlexGap>
        {ROLLING_TENORS.map(tenor => (
          <NumberInput
            key={tenor}
            min={0}
            disabled={isOverrideRolling}
            label={tenor.charAt(0).toUpperCase() + tenor.slice(1)}
            value={rollingSettings[tenor]}
            onChange={(e, value) => onRollingSettingsUpdate({ ...rollingSettings, [tenor]: value ?? 0 })}
          />
        ))}
      </Stack>

      <Stack direction="row" alignItems="center" mt={2}>
        <Switch checked={isOverrideRolling} onChange={onOverrideToggle} size="small" />
        <Typography fontSize={11}>Enable Manual tenor selection</Typography>
      </Stack>

      {isOverrideRolling && (
        <Stack direction="column" spacing={2}>
          <Box display="flex" flex={1}>
            <Box display="flex" flexWrap="wrap" flex={1} gap={1}>
              {selectedTenors.map(tenorCode => {
                const tenor = tenors.find(t => t.code === tenorCode);
                return (
                  tenor && (
                    <Chip
                      key={tenorCode}
                      label={tenor.display.toLocaleUpperCase()}
                      onDelete={() => onTenorToggle(tenorCode)}
                      size="small"
                      sx={{ fontSize: 10 }}
                    />
                  )
                );
              })}
            </Box>
            <Button variant="outlined" size="small" onClick={clearAllTenors} style={{ fontSize: 9, alignSelf: 'end' }}>
              Clear All
            </Button>
          </Box>

          <Divider>
            <Typography variant="caption" fontSize={9}>
              Add / Remove Tenors Below
            </Typography>
          </Divider>

          <TextField
            placeholder="Search tenors"
            size="small"
            value={searchInput}
            onChange={e => setSearchInput(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon sx={{ fontSize: 15 }} />
                </InputAdornment>
              ),
            }}
            fullWidth
            sx={{ '& .MuiInputBase-input': { fontSize: 11 } }}
          />

          <List sx={{ border: '1px solid #505050', maxHeight: 400, overflow: 'auto' }}>
            {Object.entries(filteredTenorGroups).map(([groupName, groupTenors], index) => (
              <Stack key={groupName}>
                <ListItemButton onClick={() => onToggleGroupClick(groupName)} sx={{ bgcolor: '#585858', py: 0 }}>
                  <ListItemText primary={groupName} primaryTypographyProps={{ fontSize: 11 }} />
                  {expandedGroups.includes(groupName) ? <ExpandLess sx={{ fontSize: 17 }} /> : <ExpandMore sx={{ fontSize: 17 }} />}
                </ListItemButton>
                <Collapse in={expandedGroups.includes(groupName)} timeout="auto" unmountOnExit>
                  <List
                    component="div"
                    disablePadding
                    sx={{
                      borderLeft: '1px solid #505050',
                      borderRight: '1px solid #505050',
                      margin: '0 0.1px',
                    }}
                  >
                    {groupTenors.map(tenor => (
                      <ListItem key={tenor.code} disableGutters>
                        <ListItemIcon>
                          <Checkbox
                            size="small"
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selectedTenors.includes(tenor.code)}
                            onChange={() => onTenorToggle(tenor.code)}
                          />
                        </ListItemIcon>
                        <ListItemText primary={<Typography fontSize={11}>{tenor.display}</Typography>} />
                        <Typography variant="caption" fontSize={11} sx={{ mr: 1 }}>
                          {tenor.display.toLocaleUpperCase()}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
                {index < Object.keys(filteredTenorGroups).length - 1 && <Divider />}
              </Stack>
            ))}
          </List>
        </Stack>
      )}
    </Stack>
  );
};
