import { priceFormatterService } from '@services/PriceFormatterService';
import { Product, ProductTenor } from '@shared/protos/product';
import { ColDef, ColGroupDef } from 'ag-grid-enterprise';
import { getBalDisplayName } from '../shared/utils';
import { TradingGridCellRenderer } from './TradingGridCellRenderer';
import { TradingRow } from './tradingTypes';

export const tenorColumn: ColDef<TradingRow> = {
  field: 'tenorName',
  colId: 'tenorId',
  headerName: '',
  suppressSpanHeaderHeight: true,
  minWidth: 80,
  width: 100,
  maxWidth: 120,
  lockPosition: 'left',
  pinned: 'left',
  cellClass: 'ag-tenor-column',
};

export const mapColumns = (products: Product[]): ColDef<any, any>[] =>
  products.reduce<(ColDef<TradingRow, number> | ColGroupDef<TradingRow>)[]>(
    (accumulator, { symbol, short_name, full_name }, colIndex) => [
      ...accumulator,
      {
        headerName: short_name,
        groupId: symbol,
        marryChildren: true,
        suppressStickyLabel: true,
        headerTooltip: full_name,
        children: [
          {
            colId: `${symbol}-sellBtn`,
            width: 55,
            cellRenderer: TradingGridCellRenderer,
            cellClassRules: {
              'ag-col-group-even': () => colIndex % 2 === 0,
              'ag-trading-sell-cell': () => true,
            },
          },
          {
            colId: `${symbol}-mid`,
            maxWidth: 50,
            cellDataType: 'text',
            valueFormatter: ({ value }) => (value === null || value === undefined ? '' : priceFormatterService.formatProductPrice(value)),
            valueGetter: ({ data }) => data?.[symbol].mid,
            valueSetter: ({ data, newValue }) => {
              if (data[symbol] === undefined) {
                return false;
              }

              data[symbol].mid = newValue;
              return true;
            },
            headerTooltip: full_name,
            resizable: true,
            cellStyle: { display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center', justifyContent: 'center' },
            cellClassRules: {
              'ag-col-group-even': () => colIndex % 2 === 0,
            },
          },
          {
            colId: `${symbol}-buyBtn`,
            width: 55,
            cellRenderer: TradingGridCellRenderer,
            cellClassRules: {
              'ag-col-group-even': () => colIndex % 2 === 0,
              'ag-trading-buy-cell': () => true,
            },
          },
        ],
      },
    ],
    [tenorColumn]
  );

export const mapRows = (tenors: ProductTenor[], products: Product[], firstMonthIndex: number, firstWeekIndex: number): TradingRow[] => {
  return tenors.map(({ code, display, frequency }, index) => {
    const newRow = {
      tenorId: code,
      tenorName: getBalDisplayName(firstMonthIndex, firstWeekIndex, index, display),
      tenorFrequency: frequency,
    };
    products.forEach(({ symbol }) => {
      newRow[symbol] = { bid: '', mid: '', ask: '' };
    });

    return newRow as TradingRow;
  });
};
