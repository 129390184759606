import { useAdminApi } from '@hooks/useAdminApi';
import { styled } from '@mui/material';
import { MidPrice } from '@protos/trading';
import { addSecondsToNow } from '@utils/date';
import { CustomCellEditorProps, useGridCellEditor } from 'ag-grid-react';
import { memo, useCallback, useEffect, useRef, useState } from 'react';

const EditorInput = styled('input')({
  width: '100%',
  height: '100%',
  padding: '0 8px',
  backgroundColor: 'white',
  color: 'black',
  border: 'none',
  outline: 'none',
  textAlign: 'center',
  fontSize: '12px',
});

export const PricingSheetCellEditor = memo((props: CustomCellEditorProps & { validity: number }) => {
  const [value, setValue] = useState(props.value);
  const inputRef = useRef<HTMLInputElement>(null);
  const { apiClient } = useAdminApi();

  useEffect(() => {
    let startValue: string;
    if (props.eventKey === 'Backspace') {
      startValue = '';
    } else if (props.eventKey && props.eventKey.length === 1 && isCharNumeric(props.eventKey)) {
      startValue = props.eventKey;
      props.onValueChange(startValue);
    } else {
      startValue = value;
    }
    setValue(startValue);

    inputRef.current?.focus();
    inputRef.current?.select();
  }, []);

  const updateValue = async (val: string) => {
    if (val === '') return;

    const newMid: MidPrice = {
      symbol: props.column.getColId() + props.data.tenorId,
      price: val,
      expiry_timestamp: addSecondsToNow(props?.validity),
      is_live: true,
      created_timestamp: new Date().toISOString(),
    };
    try {
      const response = await apiClient?.createMidPrice(newMid);
      if (response) {
        setValue(response.price);
        props.onValueChange(response.price);
      }
    } catch (error) {
      console.error(error);
      setValue(props.initialValue);
      props.onValueChange(props.initialValue);
    }
  };

  const isCharNumeric = (charStr: string) => {
    return !!/^-|\d|\./.test(charStr);
  };

  const isValidNumber = (value: string) => {
    if (value === '') return true;
    return /^-?\d*\.?\d*$/.test(value);
  };

  useGridCellEditor({
    isCancelBeforeStart() {
      return false;
    },
    isCancelAfterEnd() {
      if (value !== props.initialValue) updateValue(value);
      return false;
    },
  });

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      if (isValidNumber(newValue)) {
        setValue(newValue);
        props.onValueChange(newValue);
      }
    },
    [props.onValueChange]
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (
        !isCharNumeric(event.key) &&
        !['Backspace', 'Delete', 'Tab', 'Enter', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(event.key)
      ) {
        event.preventDefault();
        return;
      } else if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(event.key)) {
        event.preventDefault();
        const currentFocusedCell = props.api.getFocusedCell();

        if (currentFocusedCell) {
          let nextRowIndex = currentFocusedCell.rowIndex;
          let nextColumn = currentFocusedCell.column;

          if (event.key === 'ArrowUp') {
            nextRowIndex -= 1;
          } else if (event.key === 'ArrowDown') {
            nextRowIndex += 1;
          } else if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
            const allColumns = props.api.getAllDisplayedColumns();
            const currentColumnIndex = allColumns.findIndex(column => column.getColId() === currentFocusedCell.column.getColId());

            if (event.key === 'ArrowLeft' && currentColumnIndex > 0) {
              nextColumn = allColumns[currentColumnIndex - 1];
            } else if (event.key === 'ArrowRight' && currentColumnIndex < allColumns.length - 1) {
              nextColumn = allColumns[currentColumnIndex + 1];
            }
          }

          if (nextRowIndex < 0 || nextRowIndex >= props.api.getDisplayedRowCount()) return;

          props.api.stopEditing();
          props.api.clearFocusedCell();
          const newColId = nextColumn.getId();
          props.api.setFocusedCell(nextRowIndex, newColId);
        }
      }
    },
    [props.stopEditing]
  );

  return <EditorInput ref={inputRef} value={value ?? ''} onChange={onChange} onKeyDown={handleKeyDown} />;
});
