import { AppLoggerService } from './AppLoggerService';
import AppConfig from './Config';
import { ChannelTypeV1, Stream, StreamEvent, Subscription } from './Stream';
import { ChannelType, StreamEvent as StreamEventV2, StreamV2, Subscription as SubscriptionV2 } from './StreamV2';
import StringService from './StringService';
import { streamOrderService } from './streamOrderService';
import { streamProductRiskService } from './streamProductRiskService';
import { streamProductService } from './streamProductService';
import { streamRFQService } from './streamRFQService';
import { streamSnapshotsService } from './streamSnapshotsService';
import { streamV2OrderBookTopService } from './v2/streamV2OrderBookTopService';
import { streamV2ProductRiskService } from './v2/streamV2ProductRisk';
import { streamV2ProductService } from './v2/streamV2ProductService';
import { streamV2RFQService } from './v2/streamV2RFQService';

export const config = AppConfig.fromDocument();
export const logger = new AppLoggerService(config);
export const stringService = new StringService();
export const stream = new Stream(config.wsUrl);
export const streamProduct = streamProductService(stream);
export const streamRFQ = streamRFQService(stream);
export const streamProductRisk = streamProductRiskService(stream);
export const streamOrders = streamOrderService(stream);
export const streamSnapshots = streamSnapshotsService(stream);

//V2 Streams
export const streamV2 = new StreamV2(config.wsUrlV2);
export const streamV2RFQ = streamV2RFQService(streamV2);
export const streamV2Product = streamV2ProductService(streamV2);
export const streamV2OrderBookTop = streamV2OrderBookTopService(streamV2);
export const streamV2ProductRisk = streamV2ProductRiskService(streamV2);

// Server Info setup for V1 Websocket
stream.onConnect(() => {
  stream.subscribe(Subscription.serverInfo());
}, ChannelTypeV1.ServerInfo);

stream.onEvent(ChannelTypeV1.ServerInfo, (event: StreamEvent) => {
  stream.uid = event.message.uid;
});

// Server Info setup for V2 Websocket
streamV2.onConnect(() => {
  streamV2.subscribe(SubscriptionV2.serverInfo());
}, ChannelType.ServerInfo);

streamV2.onEvent(ChannelType.ServerInfo, (event: StreamEventV2) => {
  streamV2.uid = event.message.uid;
});
