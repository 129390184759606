import NumberInput from './NumberInput';

interface ValidityInputProps {
  value: string;
  onChange: (value: number | undefined) => void;
}

export const ValidityInput = ({ value, onChange }: ValidityInputProps) => {
  return (
    <NumberInput
      value={value}
      onChange={onChange}
      label="Validity (s)"
      min={1}
      width="fit-content"
      sx={{
        '& .MuiInputBase-input': {
          fontSize: '11px',
          '--Input-minHeight': '0',
        },
        '& .MuiInputLabel-root': {
          fontSize: '11px',
          '&.Mui-focused, &.MuiFormLabel-filled': {
            fontSize: '13px',
          },
        },
        '& .MuiInputBase-root': {
          borderRadius: '0px',
          height: 35,
        },
      }}
    />
  );
};
