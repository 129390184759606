import { FormControl, MenuItem, SxProps, TextField } from '@mui/material';
import { StyledBox, StyledInputLabel } from './TextInput';

type Props = {
  label: string;
  value: string;
  onChange?: (value: any) => void;
  sx?: SxProps;
  options: string[];
};

export const TextInputSelectBox: React.FC<Props> = ({ label, value, onChange, sx, options }) => {
  return (
    <StyledBox sx={{ padding: '8px 10px 13px', ...sx }}>
      <FormControl variant="standard" fullWidth sx={{ height: '100%' }}>
        <StyledInputLabel>{label}</StyledInputLabel>
        <TextField
          select
          defaultValue={value}
          onChange={value => {
            if (onChange) onChange(value.target.value);
          }}
          variant="standard"
          sx={{
            height: 'fit-content',
            paddingTop: '16px',
            ...sx,
          }}
        >
          {options.map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    </StyledBox>
  );
};
