import { useAdminApi } from '@hooks/useAdminApi';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Switch, Typography } from '@mui/material';
import { Product } from '@protos/product';
import { useCallback, useEffect, useState } from 'react';

interface EditProductDialogProps {
  product: Product;
  isOpen: boolean;
  onClose: () => void;
  onProductUpdated: (product: Product) => void;
}

export const EditProductDialog: React.FC<EditProductDialogProps> = ({ product: productProp, isOpen, onClose, onProductUpdated }) => {
  const { apiClient } = useAdminApi();

  const [product, setProduct] = useState<Product>(productProp);

  const onUpdateProduct = useCallback(
    (newFields: { enabled: boolean; private: boolean; tradable: boolean }) => {
      if (apiClient && product) {
        apiClient.updateProduct(product.symbol, newFields).then(data => {
          setProduct(data);
          onProductUpdated(data);
        });
      }
    },
    [apiClient, product]
  );

  useEffect(() => {
    setProduct(productProp);
  }, [productProp]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      disableEnforceFocus={false}
      disableAutoFocus={false}
      PaperProps={{
        component: 'form',
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          onUpdateProduct({ enabled: product.enabled, private: product.private, tradable: product.tradable });
          onClose();
        },
      }}
    >
      <DialogTitle>Edit Product</DialogTitle>
      <DialogContent>
        <Box display="flex" marginRight={2} gap={1}>
          <Stack direction="row" alignItems="center">
            <Switch
              key={`${product.symbol}-enabled-switch`}
              id="name"
              name="enabled"
              size="small"
              checked={product.enabled}
              onChange={() => setProduct(prevProduct => ({ ...prevProduct, enabled: !prevProduct.enabled }))}
            />
            <Typography fontSize={11}>Enabled</Typography>
          </Stack>
          <Stack direction="row" alignItems="center">
            <Switch
              key={`${product.symbol}-private-switch`}
              id="name"
              name="private"
              size="small"
              checked={product.private}
              onChange={() => setProduct(prevProduct => ({ ...prevProduct, private: !prevProduct.private }))}
            />
            <Typography fontSize={11}>Private</Typography>
          </Stack>
          <Stack direction="row" alignItems="center">
            <Switch
              key={`${product.symbol}-tradable-switch`}
              id="name"
              name="tradable"
              size="small"
              checked={product.tradable}
              onChange={() => setProduct(prevProduct => ({ ...prevProduct, tradable: !prevProduct.tradable }))}
            />
            <Typography fontSize={11}>Tradable</Typography>
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} size="small" style={{ fontSize: 11 }}>
          Cancel
        </Button>
        <Button type="submit" variant="contained" size="small" style={{ fontSize: 11 }}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
