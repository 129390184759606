import { lazy } from 'react';
import { Navigate, Outlet, Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

// EAGER LOADING PAGES
import { AuthenticationGuard } from '@auth/AuthenticationGuard';
import { Auth0ProviderWithNavigate } from '@auth/auth0-provider-with-navigate';
import { AlertsPage } from '@pages/AlertsPage';
import { CotPage } from '@pages/CotPage';
import { HallOfFame } from '@pages/HallOfFame';
import NotFoundPage from '@pages/NotFoundPage';
import PrivatePage from '@pages/PrivatePage';
import { ProductPage } from '@pages/ProductPage';
import ProductsPage from '@pages/ProductsPage';
import { ProfilePage } from '@pages/ProfilePage';
import { SettlementsPage } from '@pages/SettlementsPage';
import { TasksPage } from '@pages/TasksPage';
import { TradingAdminPage } from '@pages/TradingAdminPage';
import { Users } from '@pages/UsersPage';
import { OCLTrading } from '@pages/ocl-trading/OCLTrading';
import { OutliersAdminPage } from '@pages/outliers/OutliersAdminPage';
import { OutlierContractAdminPage } from '@pages/outliers/OutliersContractAdminPage';
import { config } from '@services/context';

// LAZY LOADING PAGES
const AdminPage = lazy(() => import('@pages/AdminPage'));
const DashboardPage = lazy(() => import('@pages/DashboardPage'));

export const AppRoutes = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route path="/cot" element={<CotPage />} />
        {/* KEEP THE FOUNDING FATHERS PAGE OR BAD LUCK FOR FIVE YEARS HAHA */}
        <Route path="/founding-fathers" element={<HallOfFame />} />
        <Route
          element={
            <Auth0ProviderWithNavigate>
              <PrivatePage />
            </Auth0ProviderWithNavigate>
          }
        >
          <Route element={<AuthenticationGuard component={Outlet} />}>
            <Route
              path="admin"
              element={
                <AdminPage>
                  <Outlet />
                </AdminPage>
              }
            >
              <Route path="" element={<Navigate to="/admin/products" />} />
              <Route path="products">
                <Route path="" element={<ProductsPage />} handle={{ crumbName: 'Admin Products' }} />
                <Route path=":symbol" element={<ProductPage />} handle={{ crumbName: 'Admin Product details' }} />
              </Route>
              <Route path="settlements" element={<SettlementsPage />} handle={{ crumbName: 'Admin Settlements' }} />
              <Route path="alerts" element={<AlertsPage />} handle={{ crumbName: 'Admin Alerts' }} />
              <Route path="trading">
                <Route path="" element={<TradingAdminPage />} handle={{ crumbName: 'Trading Admin' }} />
                <Route path=":activeTab" element={<TradingAdminPage />} handle={{ crumbName: 'Trading Admin' }} />
              </Route>
              <Route path="outliers">
                <Route path="" element={<OutliersAdminPage />} handle={{ crumbName: 'Outliers - Admin' }} />
                <Route path=":contractSymbol" element={<OutlierContractAdminPage />} handle={{ crumbName: 'Outliers Contract - Admin' }} />
              </Route>
              <Route path="tasks">
                <Route path="" element={<TasksPage />} handle={{ crumbName: 'Tasks - Admin' }} />
              </Route>
              <Route path="users">
                <Route path="" element={<Users />} handle={{ crumbName: 'Users - Admin' }} />
                <Route path=":id" />
              </Route>
            </Route>

            <Route path="dashboard">
              <Route index path="" element={<DashboardPage />} handle={{ crumbName: 'Dashboard' }} />
              <Route path=":dashboardId" element={<DashboardPage />} handle={{ crumbName: 'Dashboard' }} />
            </Route>
            <Route path="/manage-account" element={<ProfilePage />} handle={{ crumbName: 'Manage Account' }} />
            <Route path="/" element={<Navigate to="dashboard" />} />
            <Route path="/hall-of-fame" element={<HallOfFame />} handle={{ crumbName: 'Hall of Fame' }} />

            {!config.isProd && (
              <Route path="/ocl-trading">
                <Route path="" element={<OCLTrading />} handle={{ crumbName: 'OCL Trading' }} />
                <Route path=":activeTab" element={<OCLTrading />} handle={{ crumbName: 'OCL Trading' }} />
              </Route>
            )}
          </Route>
          <Route path="*" element={<NotFoundPage />} handle={{ crumbName: 'Not Found' }} />
        </Route>
      </Route>
    )
  );

  return <RouterProvider router={router} />;
};
