import { BlotterWidget } from '@shared/components/BotterWidget';
import { useAdminApi } from '@shared/hooks/useAdminApi';
import { USER_COLUMNS, USER_FILTERS } from '@shared/protos/user';
import { ColDef } from 'ag-grid-enterprise';

import { NavigateFunction, useNavigate } from 'react-router-dom';

const getRowId = ({ data }): string => data?.id?.toString();

const defaultColDef = (navigate: NavigateFunction) => {
  return {
    sortable: true,
    filter: false,
    floatingFilter: false,
    resizable: true,
    onCellClicked(event) {
      if (event.column.getColId() === 'id') {
        navigate(`/admin/users/${event.data.id}`);
      }
    },
    cellStyle(params) {
      if (params.column.getColId() === 'id') {
        return { cursor: 'pointer' };
      }
      return null;
    },
  } as ColDef;
};

export const Users = () => {
  const { apiClient } = useAdminApi();
  const dataLoader = apiClient?.usersLoader();
  const navigate = useNavigate();

  if (!dataLoader) return null;

  return (
    <BlotterWidget
      dataLoader={dataLoader}
      columnDefs={USER_COLUMNS}
      getRowId={getRowId}
      filters={USER_FILTERS}
      defaultColDef={defaultColDef(navigate)}
      autoSizeStrategy={{ type: 'fitCellContents' }}
      enableCellTextSelection
    />
  );
};
