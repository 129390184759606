import Box, { BoxProps } from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Input, { InputProps } from '@mui/material/Input';
import InputLabel, { InputLabelProps } from '@mui/material/InputLabel';
import { SxProps, styled } from '@mui/material/styles';
import { unstable_useId as useId } from '@mui/utils';
import useInput from '@shared/hooks/useInput';
import { ReactNode, useRef } from 'react';
import { StyledOptionalMessage } from './StyledComponents';

interface StyledBoxProps extends BoxProps {
  disabled?: boolean;
}
interface StyledInputProps extends InputProps {
  suffix?: string;
  length?: number;
}

export const StyledBox = styled((props: StyledBoxProps) => <Box {...props} />, {
  shouldForwardProp: (propName: string) => propName !== 'disabled',
})(({ theme, disabled }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  padding: '13px 10px',
}));

const StyledInput = styled((props: StyledInputProps) => <Input {...props} />, {
  shouldForwardProp: (propName: string) => propName !== 'suffix' && propName !== 'multiplier',
})(({ theme, suffix, length }) => ({
  color: theme.palette.text.input,
  fontFamily: theme.typography.fontFamily,
  fontWeight: theme.typography.fontWeightBold,
  '& .MuiInputBase-input': {
    position: 'relative',
    padding: '4px 0 0 0',
    '&::placeholder': {
      fontWeight: 'inherit',
      textTransform: 'none',
    },
  },
  ...(suffix &&
    length && {
      '&.MuiInputBase-root::after': {
        content: `'${suffix}'`,
        position: 'absolute',
        bottom: -1,
        left: `calc(${length}px + 0.3em)`,
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeightBold,
        fontFamily: theme.typography.fontFamily,
      },
    }),
}));

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 10,
  fontWeight: theme.typography.fontWeightMedium,
  textTransform: 'uppercase',
  transform: 'none',
  '&.Mui-focused': {
    color: theme.palette.text.secondary,
  },
}));

export type TextInputProps = {
  label?: ReactNode;
  error?: boolean;
  inputProps?: InputProps;
  boxProps?: BoxProps;
  labelProps?: InputLabelProps;
  disabled?: boolean;
  helper?: ReactNode;
  suffix?: string;
  adornment?: 'optional' | 'readOnly';
  maxLength?: number;
  onClick?: () => void;
  sx?: SxProps;
  defaultValue?: string;
};

/**
 * It makes sure the input value is not set to `null` which is not allowed.
 * The warning `value prop on input should not be null` is caused when we set an initial value for an input to `null`
 * or override the initial value setting it to `null`.
 */
function getInputProps(props: InputProps | undefined): InputProps {
  if (!props) {
    return { defaultValue: '' };
  }

  return { ...props, value: props?.value === null ? '' : props?.value };
}

export default function TextInput({
  label,
  error,
  inputProps,
  boxProps,
  labelProps,
  disabled,
  adornment,
  helper,
  suffix,
  maxLength = 50,
  onClick,
  sx,
  defaultValue,
}: TextInputProps) {
  const inputRef = useRef<HTMLInputElement>();
  const id = `styledinput_${useId()}`;
  const { getTextWidth } = useInput();

  const onTextInputClick = () => {
    inputRef.current?.focus();
    if (onClick) onClick();
  };

  return (
    <StyledBox onClick={onTextInputClick} disabled={disabled} {...boxProps} sx={sx}>
      <FormControl variant="standard" fullWidth error={error} disabled={disabled}>
        <StyledInputLabel htmlFor={id} shrink {...labelProps}>
          {label}
        </StyledInputLabel>
        <StyledInput
          id={id}
          disableUnderline
          inputRef={inputRef}
          suffix={suffix}
          length={getTextWidth(inputProps?.value)}
          inputProps={{ maxLength, sx: { minWidth: getTextWidth(inputProps?.value) } }}
          defaultValue={defaultValue}
          {...getInputProps(inputProps)}
        />
        {helper}
        {adornment === 'optional' && <StyledOptionalMessage>OPTIONAL</StyledOptionalMessage>}
        {adornment === 'readOnly' && <StyledOptionalMessage>READ ONLY</StyledOptionalMessage>}
      </FormControl>
    </StyledBox>
  );
}
