import { StyledBox } from '@features/user-account/manage/components/ApiTokenBox';
import { Box, Stack } from '@mui/material';
import { useUserProductsAndTenorsContext } from '@shared/contexts/UserProductsAndTenorsProvider';
import { ColDef } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { useEffect, useMemo, useState } from 'react';
import { MixedGridCellEditor } from './MixedGridCellEditor';
import { MixedGridSettings } from './MixedGridSettings';
import { useSharedTenorSettings } from './SharedTenorSettingsContext';

interface MixedGridProps {
  isShowTenors?: boolean;
  selectedAccount: string;
  gridId: string;
  savedData?: {
    [tenorId: string]: {
      value: string;
      spread: string;
      size: string;
    };
  };
  midPriceValidity: string;
}

interface MixedGridRow {
  tenor: string;
  value: string;
  spread: string;
  size: string;
  tenorId: string;
}

export const MixedGrid = ({ isShowTenors = false, selectedAccount, gridId, savedData, midPriceValidity }: MixedGridProps) => {
  const { selectedTenors } = useSharedTenorSettings();
  const { tenors } = useUserProductsAndTenorsContext();
  const [rowData, setRowData] = useState<MixedGridRow[]>([]);
  const [settings, setSettings] = useState({
    symbol: '',
    validity: 10,
    size: 10,
  });

  const columnDefs: ColDef[] = useMemo(
    () => [
      { field: 'tenor', headerName: '', editable: false, hide: !isShowTenors },
      {
        field: 'value',
        headerName: 'Value',
      },
      { field: 'spread', headerName: 'Spread' },
      { field: 'size', headerName: 'Size' },
    ],
    [isShowTenors]
  );

  useEffect(() => {
    const newRowData = selectedTenors.map(tenorCode => {
      const tenor = tenors.find(t => t.code === tenorCode);
      const savedValues = savedData?.[tenorCode] ?? { value: '', spread: '', size: '' };

      return {
        tenor: tenor?.display.toUpperCase() || tenorCode,
        value: savedValues.value,
        spread: savedValues.spread,
        size: savedValues.size,
        tenorId: tenorCode,
      };
    });
    setRowData(newRowData);
  }, [selectedTenors, tenors, savedData]);

  return (
    <Stack spacing={2} width="100%" height="100%">
      <MixedGridSettings settings={settings} onSettingsChange={setSettings} />
      <Box display="flex" flex={1} width="100%" height="100%">
        <StyledBox className="ag-theme-balham-dark custom-grid" sx={{ flex: 1, width: '100%', height: '100%' }}>
          <AgGridReact
            columnDefs={columnDefs}
            rowData={rowData}
            defaultColDef={{
              editable: true,
              suppressHeaderMenuButton: true,
              cellEditor: MixedGridCellEditor,
              cellEditorParams: {
                settings,
                selectedAccount,
                gridId,
                midPriceValidity,
              },
            }}
            domLayout="autoHeight"
            containerStyle={{ width: '100%', height: '100%' }}
            autoSizeStrategy={{ type: 'fitGridWidth' }}
          />
        </StyledBox>
      </Box>
    </Stack>
  );
};
